.root {
    margin: 15px 0 30px;
    font-size: 0;
    text-align: center;
}

.item {
    display: inline-block;

    &Icon {
        width: 22px;
        height: 22px;
        margin: 0 3px;
        color: var(--color-gray-carbon);
        transition: opacity var(--base-animation);

        &Active {
            color: var(--color-mango);
        }
    }
}
