@import 'mixins.pcss';

.list {
    > li > ul > li a {
        padding-left: 44px;
    }

    > li > ul > li > ul > li a {
        padding-left: 88px;
    }

    > li:not(:last-child) {
        margin-bottom: 4px;
    }

    &Item {
        @mixin default-text;

        &Title {
            display: flex;
            justify-content: space-between;
            padding: var(--indent-sm) var(--padding-sm);
            border-radius: var(--radius-sm);
            background: var(--color-snow);
            cursor: pointer;

            &Count {
                margin-left: 12px;
                color: var(--color-middle-gray-carbon);
            }

            &ArrowIcon {
                width: 16px;
                height: 16px;
                fill: var(--color-middle-gray-carbon);
                transition: var(--base-animation);
            }

            &:hover {
                background-color: var(--color-selected);
                color: inherit;
            }

            &.expanded & {
                &ArrowIcon {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
