@import 'mixins.pcss';

.content {
    width: 100%;
    max-width: 800px;
}

.actions {
    display: flex;
    gap: 0 var(--indent-md);
    margin-top: var(--indent-lg);
}

.removeCounter {
    display: flex;
    align-items: center;
    height: 45px;
    color: var(--color-blue-ads);
    text-transform: none;
    cursor: pointer;

    &Icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        margin-bottom: 2px;
    }

    &:hover {
        color: var(--color-cobalt);
    }
}

.main {
    @mixin block-base;

    min-height: 540px;
    padding: var(--indent-lg);
}

.block {
    @mixin block-base;

    margin: var(--indent-lg) 0;
    padding: 0 var(--indent-lg) 32px var(--indent-lg);
    box-shadow: var(--simple-box-shadow);

    &:first-child {
        margin-top: 0;
    }

    &Title {
        @mixin h3-text;

        padding: var(--indent-lg) 0;
    }
}

.changeStatusWrapper {
    position: absolute;
    right: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.changeStatusButton {
    margin-bottom: 10px !important;
}

.alertMessage a {
    margin-left: 5px;
}
