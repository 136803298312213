@import 'mixins.pcss';

.header {
    &Image {
        display: flex;
        justify-content: center;
        margin-bottom: var(--indent-lg);
    }

    &Title {
        text-align: center;
    }
}

.createCounterFinal {
    .img {
        display: block;
        margin: 0 auto 19px;
    }

    .title {
        text-align: center;
    }

    .content {
        text-align: center;
    }
}

.restoreAccessCounter {
    .img {
        display: block;
        width: auto;
        height: auto;
        margin: 0 auto 19px;
    }

    .title {
        text-align: center;
    }

    .content {
        text-align: center;
    }
}

.hintPopup {
    ul {
        padding-left: 30px !important;
        list-style-type: disc;

        li {
            margin-bottom: 15px !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    p {
        padding-bottom: 15px;
    }

    h3 {
        padding-bottom: 15px;
    }
}

.goalForm,
.conversionForm,
.deleteTemplate,
.templateForm,
.saveOrEdit {
    [class~='SBA-modal-footer'] {
        padding: 0 !important;
        border-top: 0 !important;
    }
}

.goalForm [class~='SBA-modal-body'] {
    @mixin customScrollbar;

    overflow: auto !important;
}

.csv {
    &UserEmail {
        color: var(--color-blue-ads);
    }
}

.buttons {
    display: flex;
    gap: 16px;
    justify-content: right;

    button {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}
