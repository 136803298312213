@import 'mixins.pcss';

.root {
    a {
        @mixin linkAnimation;

        color: var(--color-blue-ads);
        line-height: 20px;
        text-decoration: none;
    }
}

.title {
    @mixin h2-text;

    margin-bottom: var(--indent-lg);
}

.content {
    @mixin block-base;

    padding: var(--padding-lg) 0;
}

.row {
    width: 945px;
    margin: auto;
}
