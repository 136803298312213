.importDataFromYM {
    overflow: hidden;
    margin-bottom: var(--indent-lg);
    padding: var(--indent-lg);
    border: 1px solid var(--color-bg);
    border-radius: var(--radius-lg);
    background: var(--color-snow);
    box-shadow: 0 1px 2px var(--color-blue-carbon-rgb-25-opacity);

    &Header {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
    }

    &Content {
        display: flex;
        gap: 8px;
        align-items: center;
        line-height: 26px;
    }

    &Text {
        &Disabled {
            color: var(--color-gray-carbon);
        }
    }
}
