@import 'mixins.pcss';

.sample {
    overflow: visible;

    &Inner {
        @mixin descriptor-text;

        position: relative;
        width: 250px;
        margin: 20px 24px;
    }

    [class='SBA-slider-track'] {
        background: transparent !important;
    }
}

.label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 20px;

    &Left,
    &Center,
    &Right {
        width: 33.33%;
    }

    &Left {
        text-align: left;
    }

    &Center {
        text-align: center;
    }

    &Right {
        text-align: right;
    }
}
