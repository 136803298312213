@import 'mixins.pcss';

:root {
    --showMoreShift: 28px;
    --levelShift: 30px;
    --min-cell-height: 52px;
    --max-cell-height: 75px;
}

.root {
    @mixin customScrollbar;

    position: relative;
    display: flex;
    overflow-x: auto;
    height: inherit;
}

.table {
    @mixin block-base;

    flex-grow: 1;
    width: fit-content;
    margin-top: var(--indent-md);

    &Loading {
        min-height: 135px;
    }

    &Inner {
        width: 100%;
    }

    thead tr:first-child {
        > th:first-child {
            border-top-left-radius: var(--radius-md);
        }

        > th:last-child {
            border-top-right-radius: var(--radius-md);
        }
    }

    &HeadSticky tr:nth-child(1),
    &HeadSticky tr:nth-child(2) {
        border-bottom: 0;

        th {
            position: sticky !important;
            border-bottom: 0 !important;
            background-color: var(--color-snow);

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                border-bottom: 1px solid var(--color-bg);
            }
        }
    }

    &HeadSticky {
        tr:nth-child(1) th {
            top: 0;
            z-index: 2;
        }

        tr:nth-child(2) th {
            top: var(--min-cell-height);
            z-index: 1;
        }
    }

    &Row {
        height: var(--min-cell-height);
        border-bottom: 1px solid var(--color-bg);

        &.bigHeight {
            height: var(--max-cell-height);
        }

        &Average {
            background-color: var(--color-hover-and-current);
        }
    }

    &ExpandChildren {
        background-color: var(--color-hover-and-current);
    }

    &Cell {
        @mixin truncateWithEllipses;

        position: relative;
        vertical-align: middle;
        min-height: var(--min-cell-height);
        padding: var(--indent-md) var(--indent-lg);
        text-align: center;

        &:first-child {
            min-width: 340px;
            max-width: 0;
            text-align: left;
        }

        &:first-child .tableName {
            justify-content: flex-start;
        }

        &Head {
            @mixin descriptor-text;

            white-space: normal;

            &:first-child {
                vertical-align: top;
            }
        }

        &:last-child {
            padding-right: 65px;
        }

        &AddMetrics {
            position: absolute !important;
            top: 50%;
            right: var(--indent-md);
            min-height: 20px !important;
            margin-top: -13px;

            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }

        &TitleFilter {
            vertical-align: middle;
            min-height: var(--min-cell-height);
        }

        &Ordered {
            font-weight: 600;
        }

        &Position {
            width: 20px;
            margin-top: 1px;
            margin-right: 5px;
            color: var(--color-font);

            &.bold {
                font-weight: 600;
            }
        }
    }

    &DropdownMetricsHidden & {
        &Cell:last-child {
            padding-right: var(--padding-sm) !important;
        }
    }

    &Message {
        height: 190px;
    }

    &Empty {
        position: absolute !important;
        top: 50%;
        left: 0;
        width: 100%;
        margin-top: -85px;
    }

    &Name {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &WithUrl {
            align-items: flex-start;
            height: auto !important;
        }

        &Title {
            display: flex;
            overflow: hidden;
            height: 20px;
            line-height: 20px;

            &ShowUrl {
                height: auto;
            }

            &Tooltip {
                max-width: 500px;
            }

            &Text {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                color: var(--color-font);

                &Block {
                    @mixin truncateWithEllipses;

                    display: block;
                    flex-shrink: 0;
                }
            }

            &Wrapper {
                display: flex;
                align-items: center;
                overflow: hidden;
                max-width: 685px;

                > span {
                    overflow: hidden;
                }
            }

            &Link {
                @mixin secondaryLink;

                flex-shrink: 0;
            }

            &ShowUrl &Text {
                flex-direction: column-reverse;
            }

            &ShowUrl &TextBlock:first-child &Link {
                @mixin descriptor-text;

                margin: var(--indent-xs) 0;
                color: var(--color-middle-gray-carbon);
                text-wrap: unset;
            }

            &Position {
                margin-right: var(--padding-sm);

                &Num {
                    flex-shrink: 0;
                    min-width: 17px;
                    margin-right: var(--padding-sm);
                }
            }
        }

        &Level {
            height: 20px;

            &_1 {
                padding-left: 0;
            }

            &_2 {
                padding-left: calc(var(--levelShift) * 1);
            }

            &_3 {
                padding-left: calc(var(--levelShift) * 2);
            }

            &_4 {
                padding-left: calc(var(--levelShift) * 3);
            }

            &_5 {
                padding-left: calc(var(--levelShift) * 4);
            }

            &_6 {
                padding-left: calc(var(--levelShift) * 5);
            }

            &_7 {
                padding-left: calc(var(--levelShift) * 6);
            }

            &_8 {
                padding-left: calc(var(--levelShift) * 7);
            }
        }

        &Expand {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-right: var(--padding-sm);
            padding: 0;
            border: 0;
            border-radius: 50%;
            background-color: var(--color-middle-gray-carbon);
            color: var(--color-snow);
            outline: none;
            cursor: pointer;

            &Padding {
                height: 20px;
                padding-left: var(--levelShift);
            }
        }

        &OnGraph {
            position: relative;
            flex: 0 0 20px;
            width: 20px;
            height: 20px;
            margin-right: var(--padding-sm);
            border: 3px solid var(--color-snow);
            border-radius: var(--radius-sm);
            background: var(--color-snow);
            cursor: pointer;

            &:after {
                @mixin animation-base;

                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                width: 20px;
                height: 20px;
                border: 1px solid var(--color-gray-carbon);
                border-radius: var(--radius-sm);
            }

            &:hover:after {
                border-color: var(--color-middle-gray-carbon);
            }

            &Disabled {
                cursor: not-allowed;
            }
        }
    }

    &Title {
        display: inline-flex;
        vertical-align: middle;
        gap: 0 5px;
        cursor: pointer;

        &DisableSort {
            cursor: default !important;

            &:hover {
                opacity: 1 !important;
            }
        }

        &DisableSort &Tooltip {
            cursor: default;
        }

        &Tooltip {
            display: inline-flex !important;  /* IE */
            align-items: center;
            cursor: pointer;
        }

        &Buttons {
            display: inline-flex;
            align-items: center;
        }
    }

    &Filters {
        display: flex;
        flex-wrap: wrap;
        gap: 0 var(--indent-md);
        align-items: center;
        justify-content: center;
        min-height: 20px;

        &Item {
            @mixin linkAnimation;

            display: flex;
            align-items: center;
            min-height: 20px;
            color: var(--color-middle-gray-carbon);

            &Active {
                color: var(--color-blue-carbon);
            }
        }

        &Left {
            display: flex;
        }

        &Search {
            flex-grow: 0;
            height: 20px;
            margin-right: 20px;
        }

        &Icon {
            display: flex;
            align-items: center;
            color: var(--color-middle-gray-carbon);

            & svg {
                width: 16px;
                height: 16px;
            }

            &:hover {
                color: var(--color-blue-carbon);
            }
        }
    }

    .tableShowMoreRow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &Message {
            margin-left: var(--indent-lg);
            color: var(--color-middle-gray-carbon);
        }
    }

    &Showmore {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        &Label {
            margin-left: var(--indent-lg);
            color: var(--color-middle-gray-carbon);
        }
    }

    &Animated & {
        &Inner {
            & tbody {
                position: relative;
                background-color: var(--color-snow);
            }
        }

        &Row {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;

            &.animated {
                z-index: 2;
            }
        }

        &RowHead {
            position: static;
        }

        &Cell {
            width: 9%;

            &:last-child {
                padding-right: 45px;
                padding-left: 0;
            }
        }
    }
}

.bold {
    font-weight: 700;
}

.title {
    display: inline-block;
    vertical-align: middle;
}

.resetSelectedLines {
    @mixin linkAnimation;

    display: flex;
    align-items: center;
    color: var(--color-middle-gray-carbon);
    white-space: nowrap;
    cursor: pointer;

    svg {
        margin-right: 5px;
    }
}

.filter {
    &Anchor {
        @mixin linkAnimation;

        color: var(--color-blue-carbon-rgb-25-opacity) !important;
    }

    &Result {
        display: flex;
        flex-wrap: nowrap;
        height: 16px;
        white-space: nowrap;

        &Value {
            @mixin truncateWithEllipses;

            max-width: 92px;
        }

        &Icon {
            display: inline-flex;
            width: 16px;
            height: 16px;
            margin-top: 2px;
            margin-left: 4px;
            color: var(--color-lichi);
            cursor: pointer;
        }
    }

    &Icon {
        width: 16px;
        height: 16px;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    background: var(--color-snow);
}

@media (--medium) {
    .table {
        &Cell:first-child {
            min-width: 260px;
        }

        &NameTitleWrapper {
            max-width: 485px;
        }

        &Animated & {
            &Cell {
                width: 11%;
            }
        }
    }

    .resetSelectedLinesText > span {
        display: none;
    }
}
