@import 'mixins.pcss';

[class~='ant-page-header-heading-left'],
[class~='ant-page-header-heading-title'] {
    overflow: visible !important;
}

[class~='ant-page-header'] {
    @mixin default-text;
}

.root {
    margin-bottom: var(--indent-md);
}

.title {
    display: flex;
    align-items: center;

    /* todo: после обновления дизайна этот блок будет перенесен */
    &ExtraNode {
        position: relative;
        top: -3.5px;
        display: inline-flex;
        align-items: center;
        height: 41px;
        padding-left: 8px;
    }

    &Hint {
        line-height: 24px !important;
        text-align: left;

        &Link {
            margin-top: 20px;
            padding-left: 0 !important;
            line-height: 24px !important;
        }

        &Anchor {
            display: inline-flex;
            margin-left: 8px;
        }
    }

    &Head {
        @mixin h2-text;

        display: inline;

        &Button {
            @mixin linkAnimation;
        }
    }

    &Label {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        margin-left: 8px;
    }
}
