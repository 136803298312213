.root {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-bg);
}

.content {
    width: 750px;
    margin: auto;
}

.title {
    margin-bottom: var(--indent-md);
}

.description {
    margin-top: 10px;
}
