@import 'mixins.pcss';

.csv {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-blue-carbon);
    cursor: pointer;
    transition: color var(--base-animation);

    &Disabled {
        color: var(--color-blue-carbon-rgb-25-opacity);
        cursor: default;
    }

    &:not(&Disabled) &Icon {
        color: var(--color-middle-gray-carbon);
    }

    &:not(&Disabled):hover,
    &:not(&Disabled):hover &Icon {
        color: var(--color-blue-ads);
    }

    &Wrapper {
        display: flex;
    }

    &Icon {
        position: relative;
        top: 2px;
        width: 20px;
        height: 20px;
    }
}
