.filterFormHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 16px 0 8px;

    &Simple {
        margin: 8px 0 16px;
    }

    &Title {
        display: flex;
        align-items: center;
        overflow: hidden;
        max-width: 440px;

        &Text {
            font-weight: 600;
        }
    }

    &ClearBtn {
        width: 20px !important;
        padding-right: 0 !important;

        & > span {
            min-width: 0 !important;
        }
    }

    &Hint {
        display: inline-flex;
        margin-left: 4px;
    }
}
