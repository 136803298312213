.draggableListItem {
    border: 1px solid var(--color-snow);
    background: var(--color-snow);
    color: var(--font-color);
    cursor: grab;

    &Title {
        padding: 10px;
        color: var(--font-color);
    }

    &Over {
        margin: 0 -5px;
        padding: 0 5px;
        border-radius: 2px;
        background: var(--color-blue-ads);
        color: var(--color-snow);

        .draggableListItemTitle {
            color: var(--color-snow);
        }

        &Up {
            border-top: 1px solid var(--color-blue-ads);
        }

        &Down {
            border-bottom: 1px solid var(--color-blue-ads);
        }
    }

    &Draggable {
        color: var(--color-gray-carbon);
        cursor: grabbing;

        .draggableListItemTitle {
            color: var(--color-gray-carbon);
        }
    }
}
