.root {
    display: none;
    padding: 0;
}

.switch {
    display: flex;
    align-items: center;
    margin-bottom: var(--indent-md);

    &Label {
        margin-left: var(--indent-sm);
    }
}
