.commonFilters {
    display: block;

    &BtnsWrapper {
        display: flex;
        align-items: center;
    }

    &Btn {
        padding-right: 0 !important;
    }

    &ClearBtn {
        margin-left: 16px;
    }

    &Hint {
        display: inline-flex;
        height: 16px;
        padding-left: 8px;
    }
}
