@import 'mixins.pcss';

.subheader {
    margin-top: var(--indent-lg);
}

.title {
    @mixin h3-text;

    &AndIcon {
        display: flex;
        align-items: center;
        margin-bottom: var(--indent-lg);
    }
}

.hint {
    &Button {
        flex-shrink: 0;
        align-self: center;
        width: 16px;
        height: 16px;
        margin-left: var(--indent-sm);
        cursor: pointer;
    }
}
