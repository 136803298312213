.templateForm {
    display: flex;
    margin-bottom: 24px;
    background-color: var(--color-snow);

    /* TODO: разобраться с классом к input и select */
    [class~='SBA-input-wrapper'] {
        flex-grow: 1;
    }
}

.label {
    margin-right: 8px;
    line-height: 44px;
}

.templateType {
    margin-bottom: 0;

    .checkboxButton {
        top: 0 !important;
    }

    .checkboxTitle {
        margin-bottom: 10px;
        color: var(--font-color);
        line-height: 15px;
    }

    .checkboxDescription {
        color: var(--color-gray-carbon) !important;
    }
}

.buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: right;
}

.saveOrEdit,
.deleteTemplate {
    background-color: var(--color-snow);
}
