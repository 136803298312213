@import 'mixins.pcss';

.section {
    @mixin block-base;

    margin: var(--indent-lg) 0;
    margin-bottom: var(--indent-lg);
    padding: 0 var(--indent-lg) 32px var(--indent-lg);
    box-shadow: var(--simple-box-shadow);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &Title {
        @mixin h3-text;

        padding: var(--padding-lg) 0;
    }
}

.addNewUser {
    &Fields,
    &Action {
        display: flex;
        flex-direction: column;
        gap: var(--padding-md) 0;
    }

    &Button {
        width: fit-content;
        margin-top: var(--padding-md);
    }

    &Text {
        padding-top: 5px;
        color: var(--color-gray-carbon);
        font-size: 12px;
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--padding-md) 0;
}

.item {
    display: flex;
    padding-bottom: var(--padding-md);
    border-bottom: 1px solid var(--color-divider);

    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }

    &Number {
        min-width: 20px;
        margin-right: var(--indent-xs);
    }

    &Main {
        flex: 2 1 auto;
    }
}

.user {
    display: flex;
    margin-bottom: var(--indent-md);

    &Current {
        color: var(--color-gray-carbon);
    }

    &RemoveButton {
        width: 16px;
        height: 16px;
        margin-left: auto;
        cursor: pointer;
        fill: var(--color-middle-gray-carbon);

        &:hover {
            fill: var(--color-blue-carbon);
        }
    }
}

.radioGroup {
    display: flex;
    flex-direction: column;
    gap: var(--indent-sm) 0;
    align-items: flex-start;
}
