@import 'mixins.pcss';

.alert {
    display: flex;
    flex-direction: column;
    gap: var(--indent-sm) 0;
    margin-bottom: var(--indent-lg);
}

.wrapper {
    [class~='SBA-modal-content'] {
        border-radius: 8px;
        box-shadow: 0 8px 16px 0 rgba(29 39 115 / .25);
    }

    [class~='SBA-double-picker-middle'] {
        width: 0;
        height: 0;
        padding: 0;
        border: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    [class~='SBA-double-picker-focused'] {
        outline: none;
    }

    [class~='SBA-double-picker-range-wrapper'] {
        justify-content: center;
    }

    [class~='SBA-double-picker-ranges'] {
        padding: 8px 0 0 !important;
        text-align: center;
    }

    [class~='SBA-modal-close'],
    [class~='SBA-modal-footer'],
    [class~='SBA-double-picker-range-arrow'] {
        display: none;
    }

    [class~='SBA-double-picker-header'] {
        padding: 12px 0;
    }

    [class~='SBA-double-picker-dropdown'] {
        position: static !important;
        width: 100%;
        padding: 0;
    }

    [class~='SBA-double-picker'] > div:first-child {
        position: static;
    }

    [class~='SBA-double-picker-panels'] {
        padding: 0;
    }

    [class~='SBA-double-picker-panel-container'] {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-shadow: none;
    }

    [class~='SBA-double-picker-custom-footer-extra'] {
        padding: var(--indent-lg) 0 0;
    }
}

.calendar {
    position: relative;

    &Month {
        width: 315px !important;
        padding: 0 !important;

        &:first-child {
            margin-right: 30px;
        }
    }

    > div:nth-child(3) {
        position: static !important;
    }
}

.footer {
    display: flex;
    width: 100%;
    padding: var(--indent-lg) 0 0;
}

.currentDate {
    flex-grow: 1;
}

.dateRange {
    color: var(--color-blue-carbon);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -.3px;
}

.timezone {
    color: var(--color-middle-gray-carbon);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -.3px;
}

.buttons {
    display: flex;
    gap: 8px;
}

.inputs {
    display: flex;
    align-items: center;
    max-width: 264px;
    margin: 20px 0 -10px;
    border: 1px solid var(--color-blue-carbon-rgb-25-opacity);
    border-radius: var(--radius-md);
    font-size: 14px;
    transition: border-color .4s;

    &:hover {
        border-color: var(--color-middle-gray-carbon);
    }

    &Item {
        width: 125px;
        height: 35px;
        padding: 0 14px;
        outline: none;
        text-align: center;
    }

    &::placeholder {
        color: var(--color-middle-gray-carbon);
    }

    &:focus {
        border-color: var(--color-blue-ads);
    }

    svg {
        width: 11px;
        height: 16px;
        text-align: center;
        fill: var(--color-gray-carbon);
    }
}
