@import 'mixins.pcss';

.project {
    display: inline-flex;
    width: 100%;
    max-width: 370px;
    margin-right: 32px;

    &.delete {
        .itemBody {
            margin-top: 0 !important;
        }
    }

    &Body {
        width: calc(100% - 60px);
    }

    &Url {
        @mixin descriptor-text;
        @mixin animation-base color;

        display: flex;
        align-items: flex-end;
        margin-top: var(--indent-xs);
        color: var(--color-middle-gray-carbon);

        &Text {
            @mixin truncateWithEllipses;
        }

        &:hover {
            color: var(--color-blue-carbon);
        }
    }

    &Id {
        @mixin descriptor-text;

        display: flex;
        align-items: flex-end;
        margin-top: var(--indent-xs);
        color: var(--color-middle-gray-carbon);
    }

    &Status {
        /* отключено, пока не добавили иконки статусов */
        display: none;
        flex-basis: 16px;
        width: 16px;
        height: 16px;
        margin-right: var(--indent-md);
        color: var(--color-gray-carbon);

        &On {
            color: var(--colorGraph1);
        }

        /* TODO: при добавлении, проверить стили тултипа */
        &Tooltip {
            width: 16px !important;
            height: 16px !important;
        }
    }

    &Logo {
        width: 16px;
        height: 16px;
        margin-right: var(--padding-sm);
        color: var(--color-middle-gray-carbon);

        & img,
        & svg {
            width: 100%;
            height: 100%;
        }
    }
}

.title {
    @mixin animation-base color;

    display: flex;
    align-items: flex-end;
    height: 20px;
    color: var(--font-color);
    text-decoration: none;
    transition: color .2s ease-in-out;

    a&:hover {
        color: var(--color-blue-ads);
    }

    &Text {
        @mixin truncateWithEllipses;
    }
}
