.authorsSearch {
    width: 350px;
}

.loader:after {
    opacity: 0 !important;
}

/* TODO: скорректировать классы и спилить ширину и высоту после обновления @adtech-ui */
.loader [class~='SBA-loader-spinner'] {
    width: 24px !important;
    height: 24px !important;
}

.loader[class*='SBA-loader-overlay'] {
    padding-left: 24px;
}

.loader [class~='SBA-loader-spinner-with-label'] {
    right: auto !important;
}
