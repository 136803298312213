.textareaFilter {
    &Desc {
        margin-top: 4px;
        color: var(--color-middle-gray-carbon);
        font-size: 10px;
        line-height: 13px;
    }

    & [class~='SBA-select-dropdown'] {
        display: none !important;
    }
}
