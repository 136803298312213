.root {
    display: inline-flex;
    flex-direction: column;
}

.banner {
    position: relative;
    width: 88px;
    height: 31px;
    margin: 15px 0;

    &Logo {
        position: absolute;
        top: 50%;
        left: 50%;
        vertical-align: top;
        width: 74px;
        height: 26px;
        color: var(--color-snow);
        transform: translate(-50%, -41%);
    }
}

.colorDropdownContent {
    overflow: visible;
    width: 244px;
    min-width: 244px !important;
}

.colorPicker {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: space-around;
}

.colorBlock {
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &.active:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 4px;
        margin-top: -2px;
        margin-left: -2px;
        border-radius: 50%;
        background: var(--color-snow);
    }
}
