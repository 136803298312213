@import 'mixins.pcss';

.funnelGraph {
    padding: 24px 24px 32px;
    border-radius: var(--radius-lg);
    background-color: var(--color-snow);
    box-shadow: 0 1px 2px 0 var(--color-blue-carbon-rgb-25-opacity);

    &Title {
        font: 600 22px/28px var(--font-family-display);

        &Wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
        }
    }

    &Inner {
        @mixin customScrollbar;

        overflow: auto hidden;
        width: 100%;
        padding-bottom: 10px;

        & [class~='chart-funnelCustom'] text {
            font-family: var(--font-family) !important;
        }
    }

    &Head {
        display: flex;
        margin-bottom: 24px;

        &Item {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            flex-direction: column;
            gap: 4px;
            width: 200px;
            padding-right: 12px;

            &Conv,
            &Title {
                font-size: 16px;
                line-height: 24px;
            }

            &Number {
                font-size: 12px;
                line-height: 16px;
            }

            &Title {
                @mixin truncateWithEllipses;

                max-width: 100%;
            }

            &Value {
                display: flex;
                align-items: center;
                font: 600 22px/28px var(--font-family-display);
            }

            &Conv {
                position: relative;
                max-width: 80px;
                margin-left: 8px;
                padding-left: 8px;
                font-weight: 400;

                &:before {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 1px;
                    height: 15px;
                    background-color: var(--color-blue-carbon);
                }
            }
        }
    }

    &Legend {
        display: flex;
        gap: 16px;
        margin-top: 24px;

        &Item {
            position: relative;
            padding: 6px 0 6px 24px;
            border: 1px solid transparent;
            border-right: 0;
            font-size: 12px;
            line-height: 16px;
            cursor: default;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 8px;
                width: 12px;
                height: 12px;
                margin-top: -6px;
                border: 1px solid var(--color-snow);
                border-radius: 12px;
                background-color: rgb(24 78 161 / 1);
            }

            &Last {
                padding-right: 12px;
                border: 1px solid var(--color-blue-carbon-rgb-25-opacity);
                border-radius: 57px;
                background: var(--color-snow);
                color: var(--color-middle-gray-carbon);
                cursor: pointer;

                &:before {
                    background-color: rgb(0 135 205 / 1);
                }

                &:hover {
                    background: var(--color-selected);
                }
            }

            &Active {
                background: var(--color-bg);
                color: var(--color-blue-carbon);
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / .25) inset;

                &:hover {
                    background: var(--color-selected);
                }
            }

            &Wrap {
                display: flex;
                align-items: center;
            }
        }
    }
}
