@import 'mixins.pcss';

.root {
    position: relative;
}

.delimiter {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-bg);
}

.title {
    @mixin h3-text;

    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: var(--indent-lg);
}

.isRequired {
    color: var(--color-lichi);
    cursor: pointer;
}

.description {
    max-width: 495px;
    margin-bottom: 20px;
    color: var(--font-color);
}

.mainWrap {
    padding-top: 0;

    &.delimiter {
        padding-bottom: 0;
    }
}

.subWrap {
    padding: 20px 0;
}

.smallPadding {
    padding-bottom: 19px !important;
}

.normalPadding {
    padding-bottom: 27px !important;
}

.fixPadding {
    padding-bottom: 24px !important;
}

.alert {
    margin-bottom: 18px !important;
}
