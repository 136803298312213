@import 'mixins.pcss';

.root {
    &.anchorTextOutside {
        display: inline-flex;
        gap: 0 8px;
        align-items: center;
    }
}

.dropdown {
    overflow: visible;
}

.content {
    overflow-x: hidden;
    overflow-y: auto;
    padding: var(--indent-xs);
    border-radius: var(--radius-md);
    background: var(--color-snow);
    box-shadow: var(--simple-box-shadow);

    &_selectSingle {
        min-width: 150px;
    }

    &_selectMultiple {
        min-width: 185px;
    }

    &_listCustom {
        @mixin customScrollbar;

        width: 300px;
        max-height: 540px;
    }

    &_formCustom {
        overflow: visible;
        width: 300px;
    }
}

.anchor {
    > span {
        justify-content: space-between !important;
        width: 100%;
    }

    &EmptyButtonStyle {
        padding: 0 !important;
        border: 0 !important;
        background: none !important;
        box-shadow: none !important;
    }

    &Text {
        @mixin truncateWithEllipses;

        display: block;
        text-align: left;
    }

    &_middle {
        padding-right: 16px !important;
        padding-left: 16px !important;
    }

    &_small {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
}

.item {
    display: flex;
    padding: var(--indent-sm) var(--padding-sm);
    border-radius: var(--radius-sm);
    background: var(--color-snow);
    color: var(--font-color);
    cursor: pointer;

    &:hover:not(.disabled),
    &.selected,
    &.active {
        background-color: var(--color-selected);
        color: inherit;

        svg,
        path {
            fill: var(--color-blue-carbon);
        }
    }

    &.disabled {
        color: var(--color-disabled-text);
        cursor: default;
        pointer-events: none;
    }
}
