@import 'mixins.pcss';

.root {
    @mixin block-base;

    display: flex;
    width: 100%;
    min-height: 150px;
    margin-bottom: 16px;
    padding: 16px 20px;
}

.header {
    margin-bottom: 20px;

    &Title {
        @mixin h2;
    }

    &Link {
        @mixin linkAnimation;

        display: block;
        margin-top: 10px;
        color: var(--color-middle-gray-carbon);
        text-decoration: none;
    }
}

.list {
    display: flex;
    flex-direction: row;
}

.item {
    min-width: 170px;
    padding-right: 25px;

    &:last-child {
        padding-right: 0;
    }

    &Title {
        margin-bottom: 5px;
    }

    &Value {
        @mixin h2;
    }
}
