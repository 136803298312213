@import 'mixins.pcss';

.legend {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--indent-md);

    &Item {
        height: 18px;
        margin: 0 var(--indent-md) 4px 0;
        transition: opacity var(--base-animation);

        &Opacity {
            opacity: .5;
        }

        &Inner {
            display: flex;
            align-items: center;
            max-width: 280px;
            cursor: default;
        }
    }

    &Color {
        min-width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 2px;
    }

    &Title {
        @mixin truncateWithEllipses;

        width: auto;
    }
}
