@import 'mixins.pcss';

.sortCell,
.sortIcon {
    display: flex;
    align-items: center;
}

.titleCell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    cursor: pointer;
}

.totalCell {
    color: var(--color-middle-gray-carbon);
}

.sortIconReversed {
    transform: rotate(180deg);
}

.marketingTable {
    padding: 24px 24px 32px;
    border-radius: var(--radius-lg);
    background-color: var(--color-snow);
    box-shadow: 0 1px 2px 0 var(--color-blue-carbon-rgb-25-opacity);

    &Title {
        display: flex;
        align-items: center;
        font: 600 22px/28px var(--font-family-display);

        &Wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
        }
    }

    &Btns {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    &Cell {
        max-width: 800px;
        word-break: break-word;

        &Percent {
            margin-left: 4px;
            color: var(--color-middle-gray-carbon);
        }
    }

    &New {
        @mixin small-descriptor-text;

        position: relative;
        z-index: 1;
        display: flex;
        margin: 3px 0 0 8px;
        padding: 1px 12px;
        border-radius: 25px;
        color: var(--color-blue-carbon);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: 0;
            border-radius: inherit;
            background: var(--color-snow);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -2;
            margin: -1px;
            border-radius: inherit;
            background: var(--ads-gradient);
        }
    }

    & [class~='SBA-table-row'] {
        cursor: default;
    }

    &ModalAlert {
        margin-top: 16px;
    }

    &SettingsModal {
        & [class~='SBA-modal-body'] {
            @mixin customScrollbar;

            overflow: auto !important;
        }

        &Title {
            margin-bottom: 24px;
        }

        &Btn {
            margin-left: 24px;
        }

        &Settings {
            position: relative;
            z-index: 100;
        }
    }
}

.pagination {
    margin-top: 24px;
}

.tableNoResults {
    padding: 80px 0;
}

@media (--small) {
    .marketing {
        &Cell {
            max-width: 320px;
        }
    }
}
