@import 'mixins.pcss';

.selectGraph {
    display: flex;
    flex-direction: column;
    gap: 4px 0;

    &Item {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &Button {
        width: 44px;
        height: 36px;
        padding: var(--indent-sm) var(--padding-sm);
        border-radius: var(--radius-md);
        background-color: var(--color-snow);
        color: var(--color-middle-gray-carbon);
        cursor: pointer;

        &:hover,
        &Active {
            background-color: var(--color-selected);
            color: inherit;
        }
    }

    &Dropdown {
        width: auto;
    }
}

.anchorIcon {
    width: 20px !important;
    height: 20px !important;
}

.anchor {
    padding-right: 10px !important;
    padding-left: 10px !important;
}
