@import 'mixins.pcss';

.addConversionForm {
    width: 100%;
    background-color: var(--color-snow);
}

.item {
    &Head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        border-top: 1px solid var(--color-bg);

        &Title {
            @mixin h2-text;
        }

        &Remove {
            @mixin iconDefaultSize;
            @mixin linkAnimation;

            color: var(--color-middle-gray-carbon);
        }
    }

    &:first-child &Head {
        margin-top: 20px;
    }

    &Head + &Body {
        padding-left: 15px;

        .fieldsDropdownList {
            width: 250px;

            &Scroller {
                width: 265px;
            }
        }
    }

    &Body {
        margin-top: 15px;
    }

    &Type {
        margin-bottom: 15px;
    }

    &Text {
        padding-bottom: 5px;
        line-height: 15px;
    }

    &Fields {
        padding: 20px 0 15px;
    }

    &Status {
        margin-top: -10px !important;
        margin-bottom: 10px !important;
    }
}

.textarea {
    margin-top: 20px !important;
}

.checkbox {
    margin-top: 20px !important;
}

.fields {
    display: flex;
    gap: 0 10px;
    align-items: center;

    &Goals {
        position: relative;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-direction: column;
        gap: 20px 0;
        width: calc((100% - 165px) / 2);

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: var(--simple-border-color);
        }

        button {
            width: 100%;
        }
    }

    &Metrics {
        flex-grow: 0;
        flex-shrink: 0;
        width: calc((100% - 165px) / 2);

        button {
            width: 100%;
        }
    }

    &Conversion {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        min-width: 145px;
    }

    &Label {
        &Wrap {
            display: flex;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 10px;
        }
    }

    &Hint {
        margin-left: 5px;
    }

    &Item {
        display: flex;
        margin: 10px 0;

        &:first-child {
            margin-bottom: 10px;
        }

        &:last-child {
            border-bottom: 0;
        }

        &Equal {
            margin: 0 15px 10px 10px;
            font-size: 20px;
        }

        &Text {
            margin-bottom: 10px;
            font-size: 20px;
        }
    }

    &Select {
        width: 200px;
    }

    &Dropdown {
        &Anchor {
            > span {
                justify-content: space-between !important;
            }
        }

        &ListScroller {
            width: 100%;
            min-width: 285px;
            max-height: 250px;
        }
    }
}

.btn {
    &Wrapper {
        display: flex;
        gap: 0 var(--indent-md);
        justify-content: right;
        padding-top: var(--indent-lg);
        background: var(--color-snow);
    }
}
