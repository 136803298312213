@import 'mixins.pcss';

.root {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
    column-gap: var(--indent-md);
}

.view {
    @mixin block-base;
    @mixin customScrollbar;

    position: relative;
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;

    &Table {
        width: 100%;
        min-height: 190px;
    }
}

.table,
.graph {
    height: inherit;
}
