@import 'mixins.pcss';

.description + .button {
    margin-top: var(--padding-lg);
}

.counterCode {
    overflow: hidden;
    width: 100%;
    min-height: 455px;
    margin: var(--padding-lg) 0;
    border-radius: var(--radius-md) !important;
    background-color: var(--color-bg);

    &Textarea {
        @mixin customScrollbar;

        overflow-y: auto;
        width: 100%;
        min-height: 455px;
        background: var(--color-blue-carbon);
        color: var(--color-snow);
    }
}
