/*
* Breakpoints
* ======================================================================== */
@custom-media --big (min-width: 1695px);
@custom-media --medium (max-width: 1694px);
@custom-media --small (max-width: 1274px);

:root {
    /*
    * Colors
    * ======================================================================== */
    --color-bg: #e8ecf2;
    --color-blue-carbon: #333f48;
    --color-blue-carbon-rgb: rgb(51 63 72);
    --color-gray-carbon-rgb-50-opacity: rgba(171 176 185 / .5);
    --color-blue-carbon-rgb-50-opacity: rgba(51 63 72 / .5);
    --color-blue-carbon-rgb-25-opacity: rgba(51 63 72 / .25);
    --color-blue-carbon-rgb-15-opacity: rgba(51 63 72 / .15);
    --color-middle-gray-carbon: #83868d;
    --color-gray-carbon: #abb0b9;
    --color-gray-carbon-rgb: rgb(171 176 185);
    --color-hover-and-current: #f6f9fd;
    --color-snow: #fff;
    --color-cobalt: #184ea1;
    --color-cobalt--hover: #2f60aa;
    --color-cobalt--active: #254674;
    --color-indigo: #203883;
    --color-arctic-ads: #42e3b4;
    --color-arctic-ads--hover: #68e9c3;
    --color-arctic-ads--active: #3eba99;
    --color-blue-ads: #0087cd;
    --color-lichi: #ff3d00;
    --color-mango: #f5ba22;
    --color-acid-yellow: #faff00;
    --ads-gradient: linear-gradient(90deg, #42e384 0%, #1d2875 60%);
    --color-selected: #f6f9fd;
    --color-disabled-text: var(--color-blue-carbon-rgb-25-opacity);
    --oultine-initial: 0 solid #faff00;
    --oultine-visible: 2px solid #faff00;
    --color-snow-opacity: rgba(255 255 255 / .8);
    --color-divider: var(--color-blue-carbon-rgb-25-opacity);

    /*
     * Size
     * ======================================================================== */

    /* Icon */
    --iconSizeSmall: 15px; /* TODO: заменить после обновления шрифтов на 16px */
    --iconSizeMedium: 20px;
    --iconSizeLarge: 24px;

    /*
    * Fonts
    * ======================================================================== */
    --font-family: SBSansText, sans-serif;
    --font-family-display: SBSansDisplay, sans-serif;
    --font-color: var(--color-blue-carbon);

    /*
    * Shadows/borders config
    * ======================================================================== */
    --simple-border-color: var(--color-blue-carbon-rgb-25-opacity);
    --simple-box-shadow: 0 1px 2px var(--color-blue-carbon-rgb-25-opacity);
    --sparse-box-shadow: 0 8px 16px rgba(29 39 115 / .25);
    --simple-inset-box-shadow: inset 0 1px 2px rgba(0 0 0 / .25);

    /*
    * Layout
    * ======================================================================== */

    /* width */
    --minBodyWidth: 1180px;
    --rightSidebarWidth: 370px;

    /* height */
    --reportContentMinHeight: 650px;
    --footerHeight: 37px;
    --headerHeight: 72px;
    --mainHeight: calc(100vh - var(--headerHeight));
    --collapsedSidebarWidth: 32px;

    /*
    * Graphs (Old)
    * ======================================================================== */

    --colorGraph1: #2f93ff;
    --colorGraph2: #ff564b;
    --colorGraph3: #ffca39;
    --colorGraph4: #bf34e1;
    --colorGraph5: #40bfc3;
    --colorGraph6: #ce2d56;
    --colorGraph7: #ffaa30;
    --colorGraph8: #ec6ccb;
    --colorGraph9: #6a30ff;
    --colorGraph10: #fb9191;
    --colorGraph11: #e6da7a;
    --colorGraph12: #8449c5;
    --colorGraph13: #7ac944;
    --colorGraph14: #fa8500;
    --colorGraph15: #ffe200;
    --colorGraph16: #5686c8;
    --colorGraph17: #c9c744;
    --colorGraph18: #e83499;
    --colorGraph19: #e0f401;
    --colorGraph20: #50237c;

    /*
    * Other
    * ======================================================================== */

    --base-animation: .2s linear;
    --zIndexDropdown: 300;

    /*
    * Sizes
    * ======================================================================== */
    --padding-lg: 20px;
    --padding-md: 16px;
    --padding-sm: 12px;
    --indent-lg: 24px;
    --indent-md: 16px;
    --indent-sm: 8px;
    --indent-xs: 4px;
    --radius-xl: 16px;
    --radius-lg: 8px;
    --radius-md: 6px;
    --radius-sm: 4px;
    --radius-xs: 2px;

    /*
    * Breakpoints
    * ======================================================================== */

    @custom-media --big (min-width: 1695px);
    @custom-media --medium (max-width: 1694px);
    @custom-media --small (max-width: 1274px);
}
