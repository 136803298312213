@import 'mixins.pcss';

.funnelSidebar,
.funnelSidebarFilters {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    overflow: hidden;
    width: 0;
    border-radius: 0 var(--radius-xl) var(--radius-xl) 0;
    background-color: var(--color-snow);
    box-shadow: 0 0 var(--radius-xl) 0 rgb(29 39 115 / .5);
    transition: width .3s;

    &Active {
        width: 893px;
    }

    &Mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        display: none;
        border-radius: var(--radius-xl) var(--radius-xl);
        background-color: rgba(51 63 72 / .1);
        transition: opacity .3s;

        &Active {
            display: block;
        }
    }

    &Head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        border-bottom: 1px solid var(--color-divider);
    }

    &Content {
        padding: 24px;
    }

    &Title,
    &TableTitle {
        font: 600 22px/28px var(--font-family-display);
    }

    &Close svg {
        color: var(--color-middle-gray-carbon);
        cursor: pointer;

        &:hover {
            color: var(--color-blue-carbon);
        }
    }

    &TableWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }

    &Table {
        &Goal {
            position: relative;
            width: 85px;
            height: 24px;
            margin-left: auto;
            padding-left: 18px;
            color: var(--color-middle-gray-carbon);
            font-size: 12px;
            line-height: 24px;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 12px;
                height: 12px;
                margin-top: -6px;
                border-radius: 50%;
                background-color: var(--color-mango);
            }

            &Public:before {
                background-color: var(--color-arctic-ads);
            }
        }

        &Search {
            margin: 8px 0;
        }
    }

    &Table [class~='SBA-table-body'] {
        @mixin customScrollbar;
    }

    &Table [class~='SBA-table-cell'] {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    &Table [class~='ant-checkbox'] {
        top: 2px !important;
    }

    &Actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 12px 24px;
        border-radius: 0 0 var(--radius-xl) 0;
        background: linear-gradient(180deg, rgb(255 255 255 /) 0%, #fff 29.37%);
        text-align: center;
    }

    &TooltipText {
        position: relative;
        padding-left: 20px;

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--color-snow);
        }
    }
}

.funnelSidebarFilters {
    &Alert {
        margin-bottom: 24px;
    }

    &Active {
        width: 782px;
    }

    &Form {
        @mixin customScrollbar;

        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 255px);
    }
}
