@import 'mixins.pcss';

.filtersList {
    @mixin customScrollbar;

    overflow-y: auto;
    width: 50%;
    height: 100%;
    border-right: 1px solid var(--color-blue-carbon-rgb-25-opacity);

    &Menu {
        width: 100% !important;

        &Label {
            font-weight: 600;
        }

        &Label,
        &Text {
            display: flex;
            align-items: flex-start;
            line-height: 24px;
        }

        &TextValue {
            margin-right: 8px;
        }
    }

    & [class~='SBA-multilevel-menu'] {
        width: 100% !important;
        border-right: 0 !important;
    }

    & [class~='SBA-multilevel-menu-icon'] {
        display: none !important;
    }

    & [class~='SBA-multilevel-menu-submenu-open'] {
        background-color: var(--color-snow) !important;
    }
}
