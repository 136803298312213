@import 'mixins.pcss';

.counterBlock {
    overflow: hidden;
    margin-bottom: var(--indent-lg);
    border: 1px solid var(--color-bg);
    border-radius: 8px;
    background: var(--color-snow);
    box-shadow: 0 1px 2px var(--color-blue-carbon-rgb-25-opacity);

    &Header {
        padding: var(--indent-lg);
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
    }

    &Content {
        padding: 0 var(--indent-lg) 32px var(--indent-lg);
        line-height: 26px;
    }
}

.tabs {
    margin-bottom: 24px;
}

.alert {
    margin-bottom: 32px;

    &Error {
        margin-top: 32px;
    }
}

.accordion {
    margin-bottom: 24px;

    &Header {
        font-size: 16px;
    }

    &Text {
        font-size: 16px;
    }

    &Link {
        display: flex !important;
        gap: 8px;
        align-items: center;
        margin-left: auto;
        color: var(--color-blue-ads);
        font-weight: 400;
        font-size: 16px !important;
    }

    &Arrow {
        color: var(--color-blue-ads) !important;
        transition: transform .3s ease;

        &Active {
            transform: rotate(180deg);
        }
    }
}

.hint {
    margin-left: 8px;

    &Text {
        font-size: 14px;
    }

    &Email {
        text-decoration: underline;
        cursor: pointer;
    }

    &Link {
        display: inline-flex;
        gap: 4px;
        align-items: center;
        margin-left: 4px;
        color: var(--color-blue-ads) !important;
    }
}

.access {
    &Subtitle {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 16px;
    }

    &Hint {
        margin-bottom: 24px;
        font-size: 16px;
    }

    &Alert {
        font-size: 16px;
    }
}

.sync {
    display: flex;
    gap: 16px;
    align-items: flex-end;

    &Label {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 8px;

        &Text {
            font-size: 16px;
        }
    }
}

.token {
    display: flex;
    gap: 24px;
    align-items: flex-end;

    &Label {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 8px;

        &Text {
            font-size: 16px;
        }
    }
}

.linking {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;

    &Loader {
        width: auto;
    }

    &Sync {
        margin-top: 32px;
    }
}

.unlink {
    &Text {
        font-size: 16px;
    }
}

.linked {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;

    &Title {
        @mixin h2;

        margin-bottom: 8px;
    }

    &Text {
        font-size: 16px;
    }
}

.number {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    min-width: 310px;
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, #42e3b4 0%, #1d2875 60%);
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    line-height: 1;

    &Copy {
        cursor: pointer;
    }
}

.createCounter {
    &Text {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 24px;
    }

    &Btn {
        display: flex;
        gap: 8px;
        width: 100%;
        font-size: 16px !important;
    }
}

.submit {
    width: 100%;
    margin-top: 24px;
    font-size: 16px !important;
}

.direct {
    margin-bottom: var(--indent-md);

    &Text {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 24px;
    }

    &Info {
        @mixin descriptor-text;

        display: flex;
        gap: 0 var(--indent-xs);
        align-items: center;
    }
}

.table {
    [class~='ant-checkbox'] {
        margin-top: 2px;
    }

    &CellTitle {
        display: flex;
        gap: 0 var(--indent-xs);
        align-items: center;
    }
}

.pagination {
    padding-top: var(--indent-md);
    border-top: 1px solid var(--color-divider);
}
