@import 'mixins.pcss';

.search {
    background-color: var(--color-snow);

    &Input {
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        padding: 9px 35px 10px 19px;
        border: 1px solid  var(--color-gray-carbon);
        border-radius: var(--radius-md);
        color: var(--font-color);
        outline: none;
        cursor: pointer;
        transition: .2s all;

        &:hover {
            border-color: var(--color-blue-carbon-rgb-25-opacity);
        }

        &:focus {
            border-color: var(--color-blue-ads);
        }

        &::placeholder {
            color: var(--color-gray-carbon);
        }

        &Block {
            position: relative;
            margin: var(--indent-sm) var(--padding-sm);

            &:hover .searchInputIcon {
                fill: var(--color-blue-carbon-rgb-25-opacity) !important;
            }
        }

        &Icon {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 15px !important;
            height: 15px !important;
            fill: var(--color-gray-carbon) !important;
            transition: .2s all;
        }
    }

    &Result {
        @mixin customScrollbar;

        overflow-y: auto;
        max-height: 480px;

        &Item {
            @mixin default-text;
            @mixin truncateWithEllipses;

            display: block;
            padding: var(--indent-sm) var(--padding-sm);
            border-radius: var(--radius-sm);
            background: var(--color-snow);
            color: var(--font-color);
            cursor: pointer;

            &:hover,
            &Active {
                background-color: var(--color-selected);
                color: inherit;
            }

            &Active {
                cursor: default;
                pointer-events: none;
            }
        }

        &Empty {
            text-align: center;
        }
    }
}

.buttonLoadMore {
    width: 100%;
}

.loader {
    position: relative !important;
    left: 0 !important;
    min-height: 60px !important;
}

/* TODO: скорректировать классы и спилить ширину и высоту после обновления @adtech-ui */
.loader [class~='SBA-loader-spinner'] {
    width: 48px !important;
    height: 48px !important;
}
