@import 'mixins.pcss';

.table {
    overflow: hidden;
    width: 100%;
    padding-bottom: 1px;
    border-radius: 0 var(--radius-md);
}

.row {
    border-bottom: 1px solid var(--color-bg);

    &:last-child {
        border: none;
    }

    &Head {
        color: var(--color-middle-gray-carbon);
        font-size: 12px;
        line-height: 16px;

        &:last-child {
            border-bottom: 1px solid var(--color-bg);
        }
    }
}

.cell {
    position: relative;
    overflow: hidden;
    min-width: 90px;
    max-width: 90px;
    height: 44px;
    padding-right: 20px;
    line-height: 44px;

    &WithNotHiddenHeads {
        line-height: 16px;
    }

    &Clickable {
        @mixin linkAnimation;
    }

    &Bold {
        font-weight: 600;
    }

    &Inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &:first-child .cellInner {
        justify-content: flex-start;
        text-align: left;
    }

    &Content {
        @mixin truncateWithEllipses;

        display: flex;
    }

    &Title {
        max-width: 240px;
        padding: 0 20px;
    }
}

.title {
    &Link {
        overflow: hidden;
        height: 44px;

        &Wrapper {
            display: inline-flex !important;
            align-items: center;
            max-width: 100%;
            height: 100%;
        }

        &Content {
            @mixin truncateWithEllipses;

            display: block;
            min-width: 0;
            max-width: 100%;
            color: var(--font-color);
            text-decoration: none;
        }

        a&Content {
            @mixin linkAnimation;
        }
    }

    &List {
        &Item {
            &Arrow {
                vertical-align: middle;
                width: 12px;
                height: 12px;
                margin: 3px 8px 4px;
                color: inherit;
            }

            &:last-child &Arrow {
                display: none;
            }
        }
    }
}

.tooltipAnchor {
    display: flex;
    overflow: hidden;

    .titleList {
        @mixin truncateWithEllipses;

        flex-grow: 0;
    }
}

.tooltipAnchor .sort {
    margin-left: 4px;
}

@media (--medium) {
    .cellTitle {
        max-width: 140px;
    }
}
