.switch {
    display: flex;
    align-items: center;
    margin-bottom: var(--indent-md);

    &Label {
        margin-left: var(--indent-sm);
    }
}

.alertList {
    display: flex;
    flex-direction: column;
    gap: var(--indent-md) 0;
}

.titleField {
    border-top: 1px solid var(--color-bg);
}

.ratingField {
    padding-bottom: 0;
}

.ratingDesc {
    margin-bottom: 18px;
}

.ratingTitle {
    padding: var(--indent-lg) 0;
}

.rating {
    padding-top: 0 !important;
}

.settings {
    position: relative;
}

.alertMessage a {
    margin-left: 5px;
}
