.funnelTable {
    padding: 24px 24px 32px;
    border-radius: var(--radius-lg);
    background-color: var(--color-snow);
    box-shadow: 0 1px 2px 0 var(--color-blue-carbon-rgb-25-opacity);

    &Title {
        font: 600 22px/28px var(--font-family-display);

        &Wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
        }
    }

    &Cell {
        max-width: 800px;
        word-break: break-word;

        &Percent {
            margin-left: 4px;
            color: var(--color-middle-gray-carbon);
        }
    }

    & [class~='SBA-table-row'] {
        cursor: default;
    }
}

@media (--small) {
    .funnelTable {
        &Cell {
            max-width: 320px;
        }
    }
}
