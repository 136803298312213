@import 'mixins.pcss';

input::-ms-clear {
    display: none !important;
    width: 0;
    height: 0;
}

.input {
    @mixin temporary-input;
    @mixin default-text;

    width: 100%;
    padding-right: 88px;
    background-color: var(--color-snow);

    &_counter {
        float: right;
        padding: 5px 5px 0;
        color: #4e5c68;
        font-size: 12px;

        &_invalid {
            color: var(--color-lichi);
        }
    }

    &IconClear,
    &IconSearch {
        position: absolute;
        top: 0;
        z-index: 302;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        height: 100%;
        cursor: pointer;
        transition: .2s all;

        & svg {
            max-width: 100% !important;
            max-height: 100% !important;
        }
    }

    &Icons {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 88px;
    }

    &IconSearch {
        right: 12px;
    }

    &IconClear {
        right: 44px;

        &:hover {
            color: var(--color-blue-carbon);

            & svg,
            & svg > * {
                fill: currentColor !important;
            }
        }
    }

    &_error {
        border-bottom: 1px solid var(--color-lichi);
    }

    &:disabled {
        border: 1px solid #eee;
        background-color: #eee;
        color: color-mod(var(--font-color) a(40%));
    }
}
