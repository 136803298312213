.funnels {
    &Empty {
        padding: 24px;
        border-radius: var(--radius-lg);
        background-color: var(--color-snow);

        & > article {
            max-width: 100% !important;
        }
    }
}
