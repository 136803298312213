@import 'mixins.pcss';

.root {
    @mixin block-base;

    padding: 32px var(--indent-lg);
}

.title {
    @mixin h3-text;

    max-width: 460px;
    margin: 0 auto var(--indent-lg);
    text-align: center;
}

.textBlocks {
    display: flex;
    gap: 0 var(--indent-lg);
    justify-content: center;
    margin-bottom: var(--indent-lg);

    &Item {
        width: 33.333%;
        max-width: 395px;
        color: var(--color-middle-gray-carbon);
    }

    a {
        color: var(--color-blue-ads);
    }

    a:hover {
        color: var(--color-co);
    }
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--indent-lg);
}

.buttons {
    display: flex;
    gap: 0 var(--indent-lg);
    align-items: center;
    justify-content: center;
}

/* Касательно padding-top возможно нужны правки в @adtech/ui */
.root a.button {
    padding-top: 9px !important;
}
