@import 'mixins.pcss';

.keyMetrics {
    @mixin block-base;

    display: flex;
    width: 100%;
    min-height: 120px;
    margin-bottom: 16px;
    padding: 16px 20px;
}

.title {
    @mixin h3-text;

    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.list {
    display: flex;
    flex-direction: row;
}

.item {
    min-width: 150px;

    &:not(:last-child) {
        margin-right: 25px;
        padding-right: 25px;
        border-right: 1px solid var(--color-bg);
    }

    &Title {
        margin-bottom: 5px;
    }

    &Value {
        @mixin h3-text;
    }
}

@media (--medium) {
    .item {
        min-width: 130px;
    }
}
