@import 'mixins.pcss';

.restoreCounterStep {
    margin-top: 25px;
}

.head {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: var(--color-blue-carbon);
    color: var(--color-snow);
}

.row {
    display: flex;
    align-items: flex-start;
    padding: var(--padding-md) var(--padding-sm);
    border-bottom: 1px solid var(--color-bg);

    &:last-child {
        border-bottom: 0;
    }
}

.wrapper {
    display: flex;
    align-items: flex-start !important;
}

.title {
    display: block;
    width: 490px;
    margin: 0 15px 0 60px;
    line-height: 20px;
}

.date {
    width: 145px;
}

.counter {
    height: 85px;
    margin-bottom: 0 !important;
    padding: 10px 20px 15px !important;
    box-shadow: inset 0 -1px 0 0 var(--color-bg);
    cursor: pointer;

    &:last-child {
        box-shadow: inset 0 0 0 0 var(--color-snow);
    }

    &:hover {
        background-color: var(--color-bg);
    }

    &Item {
        display: inline-flex;
        align-items: flex-start;
        margin-right: var(--indent-md);
        margin-left: var(--indent-sm);
    }

    &Body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 485px;
    }

    &Logo {
        width: 16px;
        height: 16px;
        margin-top: 1px;
        margin-right: var(--indent-md);

        & img,
        & svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &ParamRow {
        display: block;
        height: 20px;
    }

    &Title {
        display: inline-block;
        overflow: hidden;
        max-width: 485px;
        color: var(--font-color) !important;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: color .4s ease-in-out;

        &:hover {
            color: var(--color-blue-ads);
        }
    }

    &Url {
        @mixin descriptor-text;
        @mixin animation-base color;

        display: flex;
        align-items: flex-end;
        max-width: 485px;
        margin-top: var(--indent-xs);
        color: var(--color-middle-gray-carbon) !important;
        line-height: 16px !important;

        &Text {
            @mixin truncateWithEllipses;
        }

        &:hover {
            color: var(--color-blue-carbon);
        }
    }

    &IdAndRating {
        display: flex;
        align-items: center;
        height: 20px;
    }

    &Id,
    &InRating {
        @mixin descriptor-text;

        margin-top: var(--indent-xs);
        margin-right: var(--indent-md);
        color: var(--color-middle-gray-carbon);
        line-height: 16px !important;
    }

    &Date {
        width: 145px;
    }
}

.footer {
    display: flex;
    align-items: center;
    height: 65px;
    padding-left: 20px;
    border: 1px solid var(--color-bg);
    border-top: unset;
}

.buttons {
    display: flex;
    gap: 0 var(--indent-md);
    margin-top: var(--indent-md);
}
