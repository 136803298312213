@import '~antd/dist/antd.compact.css';

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.SBA-empty-button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
}

.SBA-modal-wrap {
    /* z-index больше, чем у .global-tint, так как внутри модалок
       могут буть селекты и выпадающие списки */
    z-index: 101 !important;
}

.SBA-modal-mask {
    z-index: 100 !important;
}

.SBA-modal-body {
    overflow: visible !important;
}

.SBA-tooltip {
    /* z-index больше, чем в модалке, так как есть тултипы внутри модалок */
    z-index: 102 !important;
    min-width: 0 !important;
}

.SBA-tooltip-inner a:not([class]) {
    color: var(--color-blue-ads);
}

.SBA-tooltip-inner {
    padding-right: 16px !important;
}

.SBA-modal-title-inner {
    padding-right: 0 !important;
}

.SBA-message {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 24px;
    letter-spacing: -.3px;
    text-wrap: pretty;
    text-shadow: none;
}

.SBA-message .ant-message-notice-content {
    padding: 4px 16px;
    border-radius: 38px;
}

.SBA-message.green .ant-message-notice-content {
    background: var(--color-arctic-ads);
    color: var(--color-blue-carbon);
}

.SBA-message.black .ant-message-notice-content {
    background: var(--color-blue-carbon);
    color: var(--color-snow);
}

.SBA-message.red .ant-message-notice-content {
    background: var(--color-lichi);
    color: var(--color-snow);
}

.SBA-message svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-bottom: -4px;
}

.ant-radio .ant-radio-inner {
    border-color: var(--color-blue-carbon-rgb-25-opacity) !important;
}

.ant-radio.ant-radio-disabled .ant-radio-inner {
    background-color: var(--color-bg) !important;
}

.ant-radio.ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: var(--color-gray-carbon) !important;
}

/* FIXME: скорректировать после обновления версии @adtech-ui */
.SBA-loader {
    position: relative;
    width: 100%;
    min-height: 100%;
}

.SBA-loader-overlay {
    pointer-events: none;
}

.ant-dropdown:before {
    display: none !important;
}

/* TODO: разобраться, в uikit отличается цвет текста и иконки при disabled */
.SBA-btn:disabled svg,
.SBA-btn:disabled:hover svg .SBA-btn:disabled:focus svg,
.SBA-btn:disabled:active svg {
    color: var(--color-blue-carbon-rgb-25-opacity) !important;
}

.SBA-menu-item:hover {
    background-color: var(--color-selected);
}

/* FIXME: скорректировать, временный фикс */
.SBA-loader > .SBA-loader {
    display: none !important;
}

/* FIXME: нужны правки в uikit на тему padding для кнопок */
.SBA-btn-sm.has-prefix:not(.btn-round),
.SBA-input-search-button-sm.has-prefix:not(.btn-round) {
    padding-right: 12px !important;
}

/* FIXME: фиксы дропдауна-кнопки */
.SBA-empty-button.drop-down-list-item {
    gap: 10px !important;
}

.SBA-empty-button.drop-down-list-item .text {
    color: var(--color-blue-carbon) !important;
}

.SBA-empty-button.drop-down-list-item .text.text--gray {
    color: var(--color-middle-gray-carbon) !important;
}

.SBA-empty-button.drop-down-list-item .text.text--additional {
    display: none !important;
}

.SBA-empty-button.drop-down-list-item svg {
    color: var(--color-middle-gray-carbon);
}

/* FIXME: нужны правки border-color для td и th в uikit */
.SBA-table-thead {
    vertical-align: top;
}

.SBA-table-tbody > tr > td,
.SBA-table-thead > tr > th {
    border-color: var(--color-divider) !important;
}

.SBA-table .SBA-table-row:last-of-type .SBA-table-cell {
    border-bottom: 1px solid var(--color-divider) !important;
}

.SBA-table-tbody > tr.SBA-table-row-selected > td {
    background: var(--color-selected) !important;
}

.SBA-table .SBA-table-tbody > .SBA-table-row:last-of-type .SBA-table-cell:first-of-type {
    border-bottom-left-radius: 0 !important;
}

.SBA-table .SBA-table-tbody > .SBA-table-row:last-of-type .SBA-table-cell:last-of-type {
    border-bottom-right-radius: 0 !important;
}

/* FIXME: нужны правки border-color для global-tint в uikit */
.global-tint {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(51 63 72 / .05);
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s;
}

.global-tint.visible {
    opacity: 1;
    pointer-events: all;
}

/* FIXME: из-за global-tint z-index */
.SBA-select.SBA-select-open {
    z-index: 101 !important;
}

.ant-collapse {
    font-size: 16px;
    line-height: 24px;
}

.ant-progress-success-bg,
.ant-progress-bg {
    background-color: var(--color-middle-gray-carbon);
}

.drop-down-list {
    z-index: 100 !important;
    border-radius: 6px;
}

.drop-down-list .ant-dropdown-menu {
    border-radius: 6px;
}
