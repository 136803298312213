.root {
    margin-bottom: var(--indent-md);

    [class~='SBA-input'] {
        background-color: var(--color-snow);
    }
}

.switcher {
    display: flex;
    align-items: center;
    margin-top: var(--indent-md);

    &Hint {
        margin-left: 0;
    }
}
