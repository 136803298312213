@import 'mixins.pcss';

.root {
    display: inline-flex;
    flex-direction: column;
    gap: var(--indent-xs) 0;
}

.position {
    display: flex;
    align-items: flex-end;
}

.message,
.category {
    color: var(--color-middle-gray-carbon);
}

.categories {
    &Button {
        @mixin linkAnimation;

        display: flex;
        gap: 0 var(--indent-sm);
        align-items: baseline;
        color: var(--font-color);

        &Text {
            @mixin truncateWithEllipses;
        }

        svg {
            color: var(--color-middle-gray-carbon);
        }
    }

    &Item {
        &Selected {
            background-color: var(--color-selected) !important;
        }
    }
}

.buttonRating {
    width: auto;
}
