@import 'mixins.pcss';

.hint {
    position: relative;
    z-index: 1;
    display: inline-flex;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    cursor: pointer;

    &Icon {
        @mixin base-link var(--color-blue-carbon);

        display: inline-flex;
        width: 16px;
        height: 16px;

        use {
            pointer-events: none;
        }
    }

    /* TODO: спилить привязку к элементам svg после обновления иконок */
    &Icon:hover {
        color: var(--color-blue-carbon);

        > svg rect {
            fill-opacity: .5;
        }

        > svg path {
            fill: var(--color-blue-carbon);
        }
    }

    &Content {
        & a {
            & svg {
                transition: none !important;
            }
        }

        & strong {
            @mixin strong-text;
        }
    }
}
