@import 'mixins.pcss';

.root {
    position: relative;
    display: flex;
    width: 100%;
    height: inherit;
}

.table {
    @mixin block-base;

    width: 100%;

    &Head {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid var(--color-bg);
        color: var(--color-middle-gray-carbon);
    }

    &Search {
        padding: 15px 20px;
        border-bottom: solid 1px var(--color-bg);
        color: var(--color-middle-gray-carbon);
    }

    &Row {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 60px;
        padding: 10px 20px 15px;
        box-shadow: inset 0 -1px 0 0 #f3f4f7;

        &Data {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            &:last-child {
                box-shadow: inset 0 0 0 0 var(--color-snow);
            }
        }
    }

    &Title,
    &Cell {
        width: 100%;
        line-height: 15px;

        &Action {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            justify-content: flex-end;
            width: 45px;
        }
    }
}

.hovered {
    cursor: pointer;

    &:hover {
        & div {
            color: var(--color-blue-ads);
        }
    }
}

.disabled {
    color: var(--color-gray-carbon);
}

.message {
    min-height: 200px;
}
