@import 'mixins.pcss';

.table {
    [class~='SBA-table-footer'] {
        border-top: 1px solid var(--color-bg);
        background: transparent;
    }

    &Text {
        display: block;
        width: 100%;
    }

    &Title &Text,
    &Email &Text {
        @mixin truncateWithEllipses;
    }

    &Email {
        max-width: 200px;
    }

    &Protected {
        max-width: 160px;
    }

    &Title {
        max-width: 250px;
    }

    &Settings {
        max-width: 52px;

        &Icon {
            height: 24px;
            min-height: 24px;

            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }
    }

    &Condition {
        &Expand {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-right: var(--padding-sm);
            padding: 0;
            border: 0;
            border-radius: 50%;
            background-color: var(--color-middle-gray-carbon);
            color: var(--color-snow);
            outline: none;
            cursor: pointer;
        }

        &Title {
            display: flex;
            align-items: center;
        }

        &Item {
            margin: 2px 0;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &Link {
        @mixin base-link var(--font-color);
        @mixin linkAnimation;

        display: block;
        margin: 2px 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &FilterText {
        display: inline-flex;
        color: var(--color-middle-gray-carbon);

        &Inner {
            @mixin truncateWithEllipses;

            display: block;
            max-width: 350px;
        }
    }

    &NoResults {
        min-height: 200px;
        background: none;
    }
}

.loader:after {
    border-radius: var(--radius-md);
}

@media (--medium) {
    .table {
        &Email {
            max-width: 150px;
        }

        &Title {
            max-width: 200px;
        }

        &FilterTextInner {
            max-width: 300px;
        }
    }
}

@media (max-width: 1400px) {
    .tableFilterTextInner {
        max-width: 200px;
    }
}

@media (--small) {
    .table {
        &Title {
            max-width: 150px;
        }

        &FilterTextInner {
            max-width: 200px;
        }
    }
}
