.domain {
    &Root {
        padding: 20px 0 0;
    }

    &Descr {
        margin-bottom: 10px;
    }
}

.checkboxes {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-start;

    &Item {
        display: flex;
        align-items: center;
    }
}

.blockLink {
    padding-bottom: 20px;
}

.link {
    display: block;
    color: var(--color-blue-ads);
}

.inputBlackList {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    margin-left: 24px !important;
}

.customPaddingRoot {
    padding-bottom: 18px !important;
}
