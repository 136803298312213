@import 'mixins.pcss';

.content {
    display: flex;
    flex-direction: column;
    min-height: var(--reportContentMinHeight);

    &Constructor {
        height: var(--mainHeight);

        .main {
            overflow: hidden;
            height: 100%;
        }

        .mainContent {
            display: flex;
            flex-direction: column;
        }

        .mainSidebar {
            display: flex;
            flex-direction: column;
        }

        /* TODO: временное решение, нужно скорректировать после обновления дизайна */
        [class*='Navigation__root'] {
            position: relative;
            top: 0;
            max-height: calc(100% - 150px);
        }
    }
}

.extraRight {
    display: flex;
    flex-wrap: wrap;
    gap: var(--indent-md);
}

.saveReportBtn {
    min-width: 185px;

    svg {
        width: 16px !important;
        height: 16px !important;
    }
}
