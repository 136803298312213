@import 'mixins.pcss';

.noData {
    @mixin block-base;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 285px) !important;
}

.ovalIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    margin: auto;
    border-radius: 100%;
    background-color: var(--color-bg);
    text-align: center;
}

.svgSort {
    object-fit: contain;
    width: 16px;
    height: 16px;
    opacity: .4;
}

.textBlock {
    padding-top: 10px;
}

.headNoDataText {
    @mixin h2;

    text-align: center;
}

.noDataText {
    color: var(--color-middle-gray-carbon);
    text-align: center;
}
