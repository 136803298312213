.savedFiltersList {
    &:after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(51 63 72 / .05);
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s;
    }

    li {
        background-color: var(--color-snow) !important;
    }

    &Overlay {
        &:after {
            opacity: 1;
        }

        li {
            cursor: default !important;
        }

        & [class~='drop-down-list-item']:hover {
            background-color: var(--color-snow) !important;
            cursor: default !important;
        }

        .actionsAnchor {
            pointer-events: all !important;
        }
    }

    &Anchor {
        padding-left: 14px !important;

        &Text {
            display: block;
            overflow: hidden;
            max-width: 310px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        > span {
            gap: 4px;
        }

        svg {
            width: 20px;
        }
    }

    &Item {
        width: 487px !important;

        [class*='list-item-container--controls'] {
            position: relative;
            flex: 0 0 20px !important;
            align-self: flex-start;
            order: 1;
            margin-top: 2px;
            margin-left: auto;
        }

        [class*='list-item-container'],
        [class*='text'] {
            flex: 1 1 auto;
        }

        &Title {
            display: flex;
            gap: var(--indent-md);
        }

        &Type {
            flex-shrink: 0;
            width: 89px;
            margin-left: auto;
            color: var(--color-middle-gray-carbon);
        }

        &Actions li {
            background-color: var(--color-snow) !important;
        }
    }

    &Actions {
        position: absolute;
        top: 100%;
        left: 4px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 2px;
        padding: var(--indent-xs);
        border-radius: var(--radius-md);
        background: var(--color-snow);
        box-shadow: var(--simple-box-shadow);

        &Btn {
            position: relative;
            z-index: 2;
            width: 20px !important;
            height: 20px !important;
            border-color: transparent !important;
            background: transparent !important;
            box-shadow: none !important;

            & svg:hover {
                color: var(--color-blue-carbon) !important;
            }

            &Open {
                background-color: var(--color-blue-carbon-rgb-25-opacity) !important;

                svg {
                    color: var(--color-middle-gray-carbon) !important;
                }
            }
        }

        &Item {
            display: flex;
            gap: 0 8px;
            align-items: center;
            width: 100%;

            &Icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                color: var(--color-middle-gray-carbon);

                svg {
                    flex: 0 0 auto;
                }
            }
        }
    }
}
