.tableShowMoreRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &Message {
        margin-left: var(--indent-lg);
        color: var(--color-middle-gray-carbon);
    }
}
