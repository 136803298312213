@import 'mixins.pcss';

.root {
    display: flex;
    flex-direction: column;
}

.table {
    @mixin block-base;

    min-width: 100%;

    &Head {
        @mixin descriptor-text;

        display: flex;
        align-items: center;
        padding: var(--padding-md) var(--padding-sm);
        border-bottom: 1px solid var(--color-bg);
    }

    &Row {
        position: relative;
        display: flex;
        align-items: center;
        padding: var(--padding-md) var(--padding-sm);
        border-bottom: 1px solid var(--color-bg);
    }

    &Title {
        width: 100%;
        max-width: 370px;
        margin-right: 32px;

        &Head {
            max-width: 338px;
            margin-left: 32px;
        }
    }

    &Metric {
        flex-grow: 0;
        width: 120px;
        margin-right: 12px;
        cursor: default;

        &Value {
            display: flex;
            align-items: flex-end;
        }

        &Diff {
            color: var(--color-arctic-ads);

            &Down {
                color: var(--color-lichi);
            }
        }
    }

    &Position {
        width: 320px;
        padding-right: 12px;
    }

    &Recovery {
        display: flex;
        flex: 2 0 auto;
        justify-content: flex-end;
    }
}

.settings {
    flex-grow: 0;
    width: 20px;
    margin-left: auto;

    &Item {
        color: var(--font-color);

        & div {
            width: 100%;
        }

        & a {
            display: block;
            color: inherit;
        }

        &Label {
            display: flex;
            align-items: center;
        }

        &Icon {
            width: 20px;
            height: 20px;
            margin-right: var(--indent-sm);
            fill: var(--color-middle-gray-carbon);
        }
    }

    &Button {
        width: 20px;
        height: 20px;
        color: var(--color-middle-gray-carbon);
        cursor: pointer;

        &:hover {
            color: var(--color-blue-carbon);
        }
    }
}

@media (--small) {
    .table {
        &itle {
            width: 270px;
            min-width: 237px;
        }

        &Position {
            width: 150px;
            min-width: 150px;
        }
    }
}
