.funnelStep {
    position: relative;
    z-index: 100;
    width: 100%;
    margin-bottom: 4px;
    padding: 8px 12px 16px;
    border-radius: var(--radius-sm);
    background-color: var(--color-selected);
    user-select: none;

    &Head {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        & svg {
            color: var(--color-middle-gray-carbon);
            cursor: pointer;
            transition: color .2s;
        }

        & svg:hover {
            color: var(--color-blue-carbon);
        }
    }

    &Index {
        margin-right: auto;
        font-weight: 600;
    }

    &Btn {
        width: 20px;
        height: 20px;
        margin: 2px 0 0 16px;

        &:last-child svg {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }
    }

    &Inner {
        display: flex;
        margin-top: 16px;
    }

    &Conditions {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: center;

        &Btn {
            height: 38px;
        }
    }

    &Title {
        background-color: var(--color-snow);
    }

    &OrText {
        margin: 0 -8px;
    }

    &Alert {
        margin-top: 14px;
    }
}
