@import 'mixins.pcss';

.trafficSource {
    display: inline-flex;
    flex-direction: row;
    overflow: hidden;
    width: 80px;
    height: 15px;
    cursor: pointer;

    &Item {
        flex: 0 0 auto;
    }

    &Tooltip {
        min-width: 330px;

        &Content {
            display: flex;
            flex-direction: column;
        }

        &Title {
            @mixin strong-text;

            padding-bottom: 8px;
        }

        &Item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 4px;
            color: var(--color-snow);

            &:last-child {
                margin-bottom: 0;
            }

            &Name {
                flex: 1 1 auto;
            }

            &Percent {
                min-width: 60px;
                padding-left: 8px;
                font-weight: 600;
                text-align: right;
                white-space: nowrap;
            }

            &Dot {
                display: block;
                flex: 0 0 auto;
                width: 8px;
                height: 8px;
                margin-right: 8px;
                border-radius: 2px;
            }
        }
    }
}
