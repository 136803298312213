.termsAccepted {
    display: flex;
    flex-wrap: wrap;

    &Row {
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        margin-top: 60px;
    }
}

.formRow {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    margin-bottom: 20px;
}

.createButton {
    margin-top: 20px;
}

.captchaRow {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    margin-top: 20px;
}

.serverError {
    margin-top: 10px;
}
