@import 'mixins.pcss';

.projectPanel {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 2px 0;
}

.id {
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    align-items: center;
}

.idLink,
.idButtonSettings,
.idButtonCopy,
.linkAllSites,
.linkUrl {
    height: auto;
    color: var(--color-blue-ads);

    &:hover {
        color: var(--color-cobalt);
    }
}

.idButtonSettings {
    margin-left: 1px;
}

.divider {
    display: inline-flex;
    padding: 0 2px;
    color: var(--color-blue-ads);
    line-height: 20px;
}

.item {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &Info {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &Head {
        display: inline;
    }

    &Counter {
        display: flex;
        gap: 0 8px;
        align-items: center;
    }

    &Url {
        position: relative;
        top: 4px;
        left: 8px;
        display: inline-flex;
        width: 20px;
        height: 20px;
        color: var(--color-middle-gray-carbon);
        opacity: 0;
        transition: opacity .2s;

        &:hover {
            color: var(--color-blue-carbon);
        }
    }

    &CounterId {
        color: var(--color-middle-gray-carbon);
        font-size: 12px;
        line-height: 16px;
    }

    &Button {
        opacity: 0;
        transition: opacity .2s;
    }
}

.projectsModal {
    &TableSearch {
        margin-bottom: 16px;
    }

    & [class~='SBA-modal-body'] {
        @mixin customScrollbar;

        overflow: auto !important;
    }

    &Table [class~='SBA-table-cell'] {
        padding: 8px !important;
    }

    &Table [class~='SBA-table-row'] {
        cursor: default;
    }

    &Table [class~='SBA-table-row']:hover {
        .itemButton,
        .itemUrl {
            opacity: 1;
        }
    }

    &Table [class~='SBA-table']:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-snow);
        transform: translateY(-1px);
    }
}
