.otherRoot {
    padding: 20px 0 0;
}

.checkboxesItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &Checkbox {
        display: flex;
    }

    &First {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &Second {
        width: 100%;
        padding-left: var(--indent-lg);

        > div:last-child {
            margin-bottom: 20px;
        }
    }

    &Link {
        color: var(--color-blue-ads);
    }
}

.input {
    margin-bottom: 20px;

    &Label {
        margin-bottom: var(--indent-sm);
    }
}

.alert {
    margin-bottom: var(--indent-sm);
}
