/* stylelint-disable */
@import 'typography.pcss';

:root {
    @define-mixin truncateWithEllipses {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @define-mixin iconDefaultSize {
        width: 15px;
        height: 15px;
    }

    @define-mixin linkAnimation {
        cursor: pointer;
        transition: var(--base-animation);

        &:hover {
            color: var(--color-cobalt) !important;
        }
    }

    @define-mixin base-link $color: var(--color-hover-and-current) {
        cursor: pointer;
        transition: all var(--base-animation);

        > svg {
            fill: currentColor !important;
        }

        &:hover {
            color: $color;
        }
    }

    @define-mixin lowOpacityHover {
        opacity: 1;
        cursor: pointer;
        transition: var(--base-animation);

        &:hover {
            opacity: .7;
        }
    }

    @define-mixin darkBlueHover {
        cursor: pointer;
        transition: var(--base-animation);

        &:hover {
            color: var(--colorHoverBlue);
        }
    }

    @define-mixin whiteHover {
        opacity: 1;
        cursor: pointer;
        transition: var(--base-animation);

        &:hover {
            color: var(--color-snow);
        }
    }

    @define-mixin customScrollbar {
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb {
            width: 16px;
            height: 16px;
            border: 4px solid transparent;
            border-radius: 24px;
            background-clip: padding-box;
        }

        &::-webkit-scrollbar-thumb {
            color: var(--color-gray-carbon-rgb-50-opacity);
            box-shadow: inset 0 0 0 8px;

            &:hover {
                color: var(--color-gray-carbon-rgb);
            }
        }

        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }

    /*
     * Link
     * ======================================================================== */

    @define-mixin base-link
        $color: var(--color-blue-ads),
        $colorHover: var(--color-cobalt), {
        @mixin animation-base color;

        color: $color;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $colorHover;
        }
    }

    @define-mixin linkWithIcon {
        > svg {
            fill: currentColor !important;
        }
    }

    @define-mixin primaryLink {
        @mixin base-link;
    }

    @define-mixin primaryLinkWithIcon {
        @mixin primaryLink;
        @mixin linkWithIcon;
    }

    @define-mixin secondaryLink {
        @mixin base-link
            var(--color-blue-carbon),
            var(--color-cobalt);
    }

    @define-mixin secondaryLinkWithIcon {
        @mixin linkWithIcon;
        @mixin secondaryLink;
    }

    /*
     * Button
     * ======================================================================== */
    @define-mixin buttonOnlyIcon $size: 16px {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;
        transition: color .2s;
        cursor: pointer;
        color: var(--color-middle-gray-carbon);

        &:hover {
            color: var(--color-cobalt);
        }
    }

    @define-mixin buttonBase
        $colorBg: var(--color-cobalt),
        $colorBorder: var(--color-cobalt),
        $colorText: var(--color-snow),
        $colorBgHover: var(--color-cobalt--hover), {
        @mixin animation-base;

        border: 1px solid $colorBorder;
        background-color: $colorBg;
        color: $colorText;
        cursor: pointer;

        &.disabled,
        &[disabled] {
            border-color: var(--color-bg);
            background-color: var(--color-hover-and-current);
            color: var(--color-blue-carbon-rgb-25-opacity);
            cursor: default;
            pointer-events: none;
        }

        &:hover {
            background-color: $colorBgHover;
        }
    }

    @define-mixin primaryButton {
        @mixin buttonBase;
    }

    @define-mixin secondaryButton {
        @mixin buttonBase
            var(--colorSecondaryButton),
            var(--color-blue-carbon-rgb-25-opacity),
            var(--color-blue-carbon),
            var(--color-hover-and-current);
    }

    /*
     * Icon
     * ======================================================================== */

    @define-mixin iconBase
        $size: var(--iconSizeSmall),
        $color: var(--color-middle-gray-carbon), {
        display: block;
        width: $size;
        height: $size;
        color: $color;
        cursor: pointer;

        &:hover {
            color: var(--color-blue-ads);
        }
    }

    @define-mixin iconSmall $color {
        @mixin iconBase var(--iconSizeSmall), $color;
    }

    @define-mixin iconMedium $color {
        @mixin iconBase var(--iconSizeMedium), $color;
    }

    @define-mixin iconLarge $color {
        @mixin iconBase var(--iconSizeLarge), $color;
    }

    /*
     * Fonts удалить
     * ======================================================================== */

    @define-mixin h1 {
        font: 600 32px/41px var(--font-family-display);
    }

    @define-mixin h2 {
        font: 600 22px/28px var(--font-family-display);
    }

    /*
     * Block
     * ======================================================================== */

    @define-mixin block-base $color-bg: var(--color-snow) {
        border-radius: var(--radius-md);
        background-color: $color-bg;
    }

    /*
     * Animations
     * ======================================================================== */

    @define-mixin animation-base $property: all {
        transition: $property .2s linear;
    }

    @define-mixin link-animation {
        @mixin animation-base;

        cursor: pointer;

        &:hover {
            color: var(--color-blue-ads) !important;
            fill: var(--color-blue-ads) !important;
        }
    }

    /*
     * Временно: в будущем, удалять по мере замены компонентов (например, Input, Button и пр.)
     * ======================================================================== */

    @define-mixin temporary-input {
        padding: 9px var(--padding-md);
        border: 1px solid var(--color-blue-carbon-rgb-25-opacity);
        border-radius: var(--radius-md);

        &:hover:not(:disabled) {
            border-color: var(--color-middle-gray-carbon);
        }

        &:focus {
            border-color: var(--color-blue-carbon);

            & + svg {
                color: var(--color-blue-carbon);
            }
        }

        &:disabled {
            background: var(--color-hover-and-current);
        }
    }
}
