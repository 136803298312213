@import 'mixins.pcss';

.wrapper {
    padding: 24px;
    border-radius: 6px;
    background-color: var(--color-snow);
}

.line {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
}

.fieldName {
    display: block;
    min-width: 155px;

    &Start {
        align-self: flex-start;
    }
}

.input {
    background: var(--color-snow);
}

.fieldsList {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    overflow: auto;
    height: 200px;
    padding: 9px 16px;
    border: 1px solid var(--color-blue-carbon-rgb-25-opacity);
    border-radius: 6px;
}

.firstCheckbox {
    margin-bottom: 10px;
}

.buttons {
    display: flex;
    gap: 10px;
}

.urlWrapper {
    margin-top: var(--indent-md);
    padding: var(--padding-md) var(--padding-lg);
    border-radius: var(--radius-lg);
    background: var(--color-hover-and-current);
}

.btnCopy {
    margin-top: var(--indent-md);
}
