.content {
    width: 750px;
    margin: auto;
}

.createCounterRow {
    width: 100%;
}

.firstStepContent {
    padding: 0 0 20px;
}

.secondStepRoot {
    margin-bottom: 24px;
    padding: 0;
}

.secondStepSettings {
    margin-bottom: 24px;
}

.row {
    width: 495px;
    padding-top: 20px;
}

.create {
    position: relative;
    display: flex;
    gap: 10px;
    margin-top: 20px !important;
}

.invalidLabel {
    position: absolute;
    top: 15px;
    right: -170px;
    left: 220px;
    color: var(--color-lichi);
}

.invalidField {
    color: var(--color-blue-ads);
}
