@import 'mixins.pcss';

.reportSearch {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;

    &InputBlock {
        @mixin animation-base;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        width: 0;
        height: 44px;
        margin: auto 0;

        &Active {
            width: 270px;
        }

        &Overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }
    }

    &Input {
        height: 44px;
    }

    &Active {
        & .reportSearch {
            &Icon {
                display: none;
            }

            &Input {
                width: 100%;
            }
        }
    }

    &Overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 301;
    }

    &Icon {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        & svg {
            width: 20px;
            height: 20px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            opacity: 0;
        }
    }

    &Word {
        margin-left: var(--indent-sm);
    }

    &Result {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 302;
        overflow: auto;
        width: 100%;
        min-width: 190px;
        max-width: 270px !important;
        max-height: 240px;
        background: var(--color-snow);
        box-shadow: inset 0 0 0 1px #d6dde4, 0 4px 8px 0 rgba(0 13 46 / .17);
        transition: width .2s;

        &Item {
            position: relative;
            display: block;
            padding: 0 40px 0 20px;
            color: var(--font-color);
            font-size: 14px;
            line-height: 30px;
            text-decoration: none;
            white-space: nowrap;

            &Selected,
            &:hover {
                background: #dee9f2;
            }
        }
    }
}

@media (--small) {
    .reportSearchResult {
        min-width: 155px !important;
    }

    .reportSearchInput {
        padding-left: 15px;
    }

    .reportSearchInputPlaceholderIcon {
        display: none;
    }

    .reportSearchInputBlockActive {
        min-width: 155px !important;
        max-width: 230px;
    }
}
