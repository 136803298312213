.inputWrapper {
    position: relative;
    margin-bottom: 20px !important;

    &.disabled {
        label {
            color: var(--color-gray-carbon);
        }
    }

    &:last-child {
        margin-bottom: 0 !important;
    }
}

.label {
    margin-bottom: 8px;
}

.counter {
    position: absolute;
    top: 15px;
    right: -170px;
    color: var(--color-gray-carbon);
}

.counterError {
    color: var(--color-lichi);
}

.alert {
    margin-top: var(--indent-md) !important;
}

.formGroupHint {
    position: absolute;
    top: 4px;
    left: 124px;
}
