.filterChips {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-top: 16px;
    column-gap: 8px;

    &Hidden {
        overflow: hidden;
        height: 40px;
    }

    &Chip {
        max-width: 420px;
        cursor: pointer !important;
    }

    &Tooltip {
        max-width: 400px;
        pointer-events: none;
    }

    &CollapseBtn {
        margin-top: 8px;
        padding: 0 !important;

        &Up svg {
            transform: rotate(180deg);
        }
    }
}
