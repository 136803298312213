@import 'mixins.pcss';

.root {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.conversion {
    @mixin block-base;

    overflow: hidden;
    min-height: 185px;
    padding: 10px 20px;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.title {
    display: inline-flex;
    align-items: center;

    &Text,
    &Link {
        @mixin h3-text;
    }

    &Link {
        @mixin base-link var(--font-color);
        @mixin linkAnimation;

        display: block;
        margin: 2px 0;
    }

    &Icon {
        @mixin iconDefaultSize;
        @mixin lowOpacityHover;

        flex-shrink: 0;
        margin: 0 5px 3px 0;
        color: var(--color-gray-carbon);
        transition: opacity var(--base-animation);

        &Active {
            color: var(--color-mango);
        }
    }
}

.settings {
    height: 20px;
    margin-left: auto;

    &Anchor {
        svg {
            width: 20px !important;
            height: 20px !important;
        }
    }
}

.body {
    display: flex;

    &Info {
        flex-shrink: 0;
        width: 420px;
    }

    &Graph {
        width: calc(100% - 420px);
    }
}

.info {
    &Wrapper {
        display: flex;
        align-items: center;
        margin: 10px 0 20px;
        font-size: 12px;
    }

    &Email > span,
    &Access > span {
        color: var(--color-middle-gray-carbon);
    }

    &Email {
        margin-right: 10px;
    }

    &Description {
        margin-bottom: 10px;
        color: var(--color-middle-gray-carbon);
    }

    &Legend {
        display: flex;
        margin: 20px 0 10px;

        &Item {
            display: flex;
            flex-direction: column;
            width: 165px;
        }

        &Value {
            @mixin h3-text;

            display: inline-flex;
            margin-bottom: 5px;
        }

        &Title {
            display: inline-flex;
            align-items: center;

            &Color {
                width: 10px;
                height: 10px;
                margin-right: 5px;
                border-radius: 50%;
            }
        }
    }
}
