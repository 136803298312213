@import 'mixins.pcss';

.action {
    display: flex;
    align-items: center;
    margin-bottom: var(--indent-md);

    &Label {
        margin-left: var(--indent-md);
        color: var(--color-gray-carbon);

        &.error {
            color: var(--color-lichi);
        }
    }
}

.contentList {
    display: flex;
    flex: 2 0 auto;

    &Item {
        @mixin customScrollbar;

        position: relative;
        overflow-y: auto;
        width: 50% !important;
        height: 365px;
        padding: 0 var(--padding-lg);

        &:first-child {
            padding-left: 0;
            border-right: 1px solid var(--simple-border-color);
        }
    }
}

.error {
    padding: var(--padding-sm) 0;
    color: var(--color-lichi);
}

.checkbox {
    &Item {
        margin-bottom: var(--indent-md);

        [class~='ant-checkbox'] + span {
            max-width: calc(100% - 16px);
        }

        &Inner {
            display: flex;
            align-items: center;

            > button {
                max-width: 100%;
            }
        }

        &Element {
            display: flex;
            align-items: flex-start;
            overflow: hidden;
            max-width: 100%;
        }

        &Count {
            margin-left: 10px;
            color: var(--color-middle-gray-carbon);
            white-space: nowrap;
        }

        &Icon {
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
            margin-right: var(--indent-sm);
            cursor: pointer;

            &Svg {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: var(--color-middle-gray-carbon);
                color: var(--color-snow);
            }

            &:hover &Svg {
                background: var(--color-cobalt);
            }
        }

        &Container {
            display: flex;
            width: 100%;
        }

        &Label {
            display: flex !important;
            width: 100%;
        }

        &Title {
            flex: 0 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: color .1s, font-weight .1s;

            &.bold {
                font-weight: 600;
            }

            &.active {
                color: var(--color-blue-ads);
                font-weight: 600;
            }
        }

        &Sub {
            margin-top: var(--indent-md);
            margin-left: 55px;
        }
    }
}

.noItems {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 260px;
    color: var(--color-middle-gray-carbon);
    text-align: center;
    transform: translate(-50%, -50%);
}

.tagsList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--indent-sm);
    margin-top: var(--indent-lg);
}

.removeIcon {
    width: 20px;
    height: 20px;
    color: var(--color-middle-gray-carbon);
    cursor: pointer;
}

.actions {
    display: flex;
    gap: 0 var(--indent-sm);
    justify-content: flex-end;

    &Button {
        flex-shrink: 0;
        min-width: 120px;
    }
}

.listSelected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.chipButton {
    cursor: default;
}

/* TODO: скорректировать классы и спилить ширину и высоту после обновления @adtech-ui */
.loader [class~='SBA-loader-spinner'] {
    width: 24px !important;
    height: 24px !important;
}
