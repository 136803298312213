@import 'mixins.pcss';

.root {
    position: relative;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 20px;
    transform: translate(-50%, -50%);
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.message {
    @mixin h3-text;

    margin: 0 !important;
    color: var(--color-gray-carbon);
    text-align: center;
}

.iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    margin-bottom: 10px;
    border-radius: 50%;
    background-color: var(--color-bg);
}

.iconSvg {
    width: 20px;
    height: 15px;
    fill: rgba(141 150 178 / .9);
}
