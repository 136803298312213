/* stylelint-disable */

:root {
    @define-mixin h1-text {
        font-weight: 600;
        font-style: normal;
        font-size: 40px;
        font-family: var(--font-family-display);
        line-height: 51px;
        text-wrap: pretty;
    };

    @define-mixin h2-text {
        font-weight: 600;
        font-style: normal;
        font-size: 32px;
        font-family: var(--font-family-display);
        line-height: 41px;
        text-wrap: pretty;
    };

    @define-mixin h3-text {
        font-weight: 600;
        font-style: normal;
        font-size: 22px;
        font-family: var(--font-family-display);
        line-height: 28px;
        text-wrap: pretty;
    };

    @define-mixin strong-text {
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        font-family: var(--font-family);
        line-height: 24px;
        letter-spacing: -.3px;
        text-wrap: pretty;
    }

    @define-mixin default-text {
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        font-family: var(--font-family);
        line-height: 24px;
        letter-spacing: -.3px;
        text-wrap: pretty;
        text-shadow: none;
    };

    @define-mixin descriptor-text {
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        font-family: var(--font-family);
        line-height: 16px;
        letter-spacing: -.3px;
        text-wrap: pretty;
    };

    @define-mixin small-descriptor-text {
        font-weight: 400;
        font-style: normal;
        font-size: 10px;
        font-family: var(--font-family);
        line-height: 13px;
        letter-spacing: -.3px;
        text-wrap: pretty;
    };
}
