.widgetFilter {
    &ListScroller {
        width: 240px;
        min-height: 50px;
        max-height: 300px;
    }
}

.anchorIcon {
    width: 20px !important;
    height: 20px !important;
}

.anchor {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

/* TODO: скорректировать классы и спилить ширину и высоту после обновления @adtech-ui */
.loader [class~='SBA-loader-spinner'] {
    width: 24px !important;
    height: 24px !important;
}
