.root {
    z-index: 4;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: var(--color-snow);
}

.stickyTop {
    position: sticky;
    top: var(--headerHeight);
}

.stickyBottom {
    position: sticky;
    bottom: 0;
    margin-top: auto;
}
