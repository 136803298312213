@import 'mixins.pcss';

.root {
    display: flex;
    flex-direction: column;
}

.reportChart {
    @mixin block-base;

    width: 100%;
    min-height: 446px;
    padding: 24px;

    &Content {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &Loader:after {
        background: var(--color-snow) !important;
    }

    &Graph {
        &_one {
            width: 100%;
        }

        &_two {
            width: calc(50% - 24px);
        }
    }

    &Legend {
        justify-content: left;
    }

    &Empty {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        margin-top: -237px;
    }
}

.chart {
    &Header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &Switcher {
            margin-left: auto;
        }
    }

    &Graph {
        width: 100%;
        background-size: cover;
    }
}

@media (--medium) {
    .reportChart {
        flex-direction: column;
        min-height: 446px;

        &Graph {
            &_one {
                width: 100%;
            }
        }

        &Content {
            margin-right: 0;
        }
    }
}

@media (--small) {
    .reportChart {
        min-height: 354px;

        &Content {
            height: 262px;
        }

        &Graph {
            &_two {
                height: 262px;
            }
        }
    }
}
