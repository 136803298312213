.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    line-height: 45px;

    &-size {
        &-xsmall {
            margin-bottom: 2px;
            line-height: 15px;
        }

        &-small {
            position: relative;
            height: 45px;
            line-height: 45px;

            &:after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: -20px;
                display: block;
                width: calc(100% + 40px);
                height: 1px;
                background-color: var(--color-bg);
            }

            &:last-child:after {
                display: none;
            }
        }

        &-normal {
            height: 15px;
            margin-bottom: 5px;
            text-align: left;
        }

        &-big {
            height: 20px;
            margin-bottom: 22px;
            font-weight: 600;
            text-align: left;

            &.responsive-size {
                @media (--small) {
                    font-size: 15px;
                }
            }
        }
    }
}

.cell {
    position: relative;
    flex: 1;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}
