@import 'mixins.pcss';

:root {
    --settingsTitleHeight: 60px;
}

.root {
    position: relative;
    flex-grow: 0;
    width: var(--collapsedSidebarWidth);
    height: 32px;
    margin-top: var(--indent-lg);
    font-size: 16px;
    transition: width .25s;
}

.title {
    @mixin h3-text;

    height: var(--settingsTitleHeight);
    padding: var(--padding-md);
}

.inner {
    @mixin block-base;

    max-width: 0;
    height: 100%;
    opacity: 0;
    transition: all .25s;
}

.scroller {
    @mixin customScrollbar;

    overflow: hidden auto;
    height: calc(100% - var(--settingsTitleHeight));
}

.opened {
    flex-grow: 1;

    /* fix для заполнения максимальной ширины при раскрытом меню (todo: подумать над другим вариантом) */
    width: 1000px;
    max-width: 100%;

    .inner {
        max-width: 100%;
        opacity: 1;
    }

    .toggleButton {
        top: 12px;
        right: -12px;
        width: 24px !important;
        height: 24px !important;
        border-color: var(--simple-border-color) !important;
        box-shadow: none !important;
    }
}

.toggleButton {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;
    overflow: hidden;
    width: 28px !important;
    height: 28px !important;
    border-color: var(--color-snow) !important;
    box-shadow: var(--simple-box-shadow) !important;
    cursor: pointer;

    &SettingsIcon {
        width: 18px;
    }

    &ArrowIcon {
        width: 16px;
        transform: rotate(180deg);
    }
}

.tools {
    padding: 0 var(--padding-md);
}

.block {
    padding: var(--indent-lg) 0;
    border-bottom: solid 1px var(--simple-border-color);

    &Title {
        padding-bottom: var(--padding-sm);
        font-weight: 600;

        &Wrap {
            display: flex;
            justify-content: space-between;
        }
    }

    &Clear {
        display: block;
        width: 16px;
        height: 16px;
        color: var(--color-middle-gray-carbon);
        cursor: pointer;

        &:hover {
            color: var(--color-blue-carbon);
        }
    }
}

.rowSettings {
    &Item {
        display: flex;
        gap: 0 8px;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: var(--padding-sm);
        }
    }

    /* TODO: разобраться с классом к input и select */
    [class~='SBA-input-wrapper'],
    [class~='SBA-select'] {
        width: 120px !important;
        margin-left: 8px !important;
    }
}

.measures {
    &Item {
        display: flex;
        align-items: center;

        &NoSelected {
            color: var(--color-middle-gray-carbon);
        }
    }

    .measureWrapDraggable &ItemTitle {
        color: var(--color-gray-carbon);
    }

    &List {
        padding: var(--indent-xs);

        &Scroller {
            overflow: visible;
            height: auto;
        }
    }
}

.clearIcon {
    display: block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-left: 8px;
    color: var(--color-middle-gray-carbon);
    cursor: pointer;

    &:hover {
        color: var(--color-blue-carbon);
    }
}

.tabs {
    &Item {
        &Icon {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
        }
    }
}
