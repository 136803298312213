@import 'mixins.pcss';

.switch {
    display: flex;
    align-items: center;
    margin-bottom: var(--indent-md);

    &Label {
        margin-left: var(--indent-sm);
    }
}

.addGoalForm {
    width: 100%;
    background-color: var(--color-snow);
}

.checkboxWrap {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &Hint {
        margin: 1px 0 0 -3px;
    }
}

.conditions {
    margin-top: 15px;

    &Title {
        margin: 32px 0 16px;
        font-weight: 600;
    }

    &Fields {
        margin: 20px 0 0;
    }

    &Status {
        margin-top: 10px;
    }

    &Inner {
        height: calc(100vh - 565px) !important;
        margin-bottom: 0 !important;
    }
}

.btn {
    &Wrapper {
        display: flex;
        gap: 0 var(--indent-md);
        justify-content: right;
        padding-top: var(--indent-lg);
        background: var(--color-snow);
    }
}
