@import 'mixins.pcss';

.root {
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    margin: auto;
}

.text {
    width: 405px;
    margin-bottom: 33px;
    text-align: center;

    & h2 {
        margin-bottom: 9px;
    }

    & a {
        @mixin linkAnimation;

        color: var(--color-blue-ads);
        text-decoration: none;
    }

    & span {
        word-break: break-all;
    }
}

span.button {
    @mixin linkAnimation;

    color: var(--color-blue-ads);
    word-break: normal;
}
