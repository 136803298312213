.сreateOrEditSavedFilterModal {
    &Label {
        margin-bottom: var(--indent-sm);
    }

    &Type {
        margin-top: var(--indent-md);
        margin-bottom: 8px;
    }

    &Description {
        margin-top: 8px;
        color: var(--color-middle-gray-carbon);
    }
}
