@import 'mixins.pcss';

.root {
    display: flex;
    width: 100%;
    margin-bottom: var(--indent-lg);
}

.period {
    display: flex;
    gap: 0 24px;
    align-items: center;

    &Label {
        display: flex;
        gap: 0 8px;
        align-items: center;
    }

    &Label svg {
        fill: var(--color-middle-gray-carbon);
    }
}

.datePickerButton {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.params {
    display: flex;
    flex-direction: column;

    /* gap: var(--indent-md) 0; */
    width: 100%;
}

.mainParams {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 24px;
    align-items: flex-start;
    margin-bottom: 16px;
    padding-right: var(--indent-sm);
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(51 63 72 / .25);

    &Item {
        display: flex;
        gap: 0 8px;
        align-items: center;
    }
}
