@import 'mixins.pcss';

.root {
    margin-top: var(--indent-md);
    padding: var(--padding-md) var(--padding-lg);
    border-radius: var(--radius-lg);
    background: rgb(51 63 72 / .15);

    a {
        color: var(--font-color);
    }
}

.text {
    & p,
    & ul {
        margin: 4px 0;
    }

    & a {
        @mixin base-link var(--color-cobalt);

        color: var(--color-blue-ads);
    }
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    &More {
        margin-right: 15px;
        color: var(--color-blue-ads);
        cursor: pointer;
    }

    &Close,
    &CloseAll {
        color: var(--color-middle-gray-carbon);
        cursor: pointer;

        &:hover {
            color: var(--color-blue-carbon);
        }
    }
}

.popup {
    &Content {
        @mixin customScrollbar;

        overflow: scroll;
        max-height: 400px;
    }

    ul {
        padding-left: 30px !important;
        list-style-type: disc;
    }

    p {
        padding-bottom: 15px;
    }

    h3 {
        padding-bottom: 15px;
    }
}
