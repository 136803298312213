@import 'mixins.pcss';

/* TODO: нужно скорректировать и избавиться от сложных перебиваний в стилях после правок в UIKit */

.root {
    position: sticky;
    top: var(--headerHeight);
    display: flex;
    width: 1000px;
    max-width: 100%;
    max-height: 100%;
    transition: width .2s ease-out;

    button {
        cursor: pointer;
    }

    [class~='SBA-multilevel-menu-icon'] {
        cursor: pointer;
    }

    [class~='SBA-multilevel-menu'] {
        @mixin customScrollbar;

        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }

    [class~='SBA-multilevel-menu-container'] li svg {
        align-self: flex-start !important;
        margin-top: 2px !important;
    }

    [class~='SBA-multilevel-menu-title-content'] {
        margin-left: 0 !important;
        line-height: 24px !important;
    }
}

.menuTitle {
    flex-grow: 1 !important;
    margin-left: 0 !important;
}

.menuClosed {
    width: var(--collapsedSidebarWidth);
}
