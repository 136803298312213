@import 'mixins.pcss';

.funnels {
    &Tools {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &Select {
        width: 100%;
    }

    &Content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
    }

    &Modal {
        opacity: 1;

        &Inner {
            height: 100%;
        }

        &Info {
            margin: 24px 0;
        }

        &AddBtn {
            margin-bottom: 24px;
            transition: none;
        }

        &Alert {
            width: 100%;
            margin-bottom: 24px;
        }

        &EmptyWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 200px);
            min-height: 225px;
        }

        &Content {
            margin-bottom: 24px;
        }

        &Content + &AddBtnWrap {
            margin-top: -8px;
        }

        &InputTitle {
            margin-bottom: 8px;
        }

        & [class~='SBA-modal-body'] {
            @mixin customScrollbar;

            overflow: auto !important;
            background-color: #fff;
        }
    }

    &DeleteModalText {
        text-align: center;
    }

    &TableSettingsModal {
        & [class~='SBA-modal-body'] {
            @mixin customScrollbar;

            overflow: auto !important;
        }

        &Title {
            margin-bottom: 24px;
        }

        &Btn {
            margin-left: 24px;
        }

        &Settings {
            position: relative;
            z-index: 100;
        }
    }
}
