.root {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 31;
    background: var(--color-snow);
}

/* TODO: нужны доработки в @adtech-ui */
.notAuthorized [class~='ant-dropdown-trigger'] {
    display: none;
}

[class~='SBA-account-menu-body'] {
    display: none;
}

[class~='SBA-account-menu'] button {
    cursor: pointer;
}

/* TODO: uikit, временные фикс для мобилки */
@media screen and (max-width: 1000px) {
    .root {
        z-index: 101;
    }
}
