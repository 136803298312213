.grid {
    margin: var(--indent-md) 0;

    &Row {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
    }

    &Column {
        display: flex;
        flex-grow: 1;
        width: 25%;
        padding: 8px;

        &_2 {
            flex-grow: 2;
            width: 50%;
            max-width: 50%;
        }

        &_3 {
            flex-grow: 3;
            width: 75%;
            max-width: 75%;
        }
    }
}

@media (--medium) {
    .grid {
        &Column {
            width: 33.333%;

            &_2 {
                width: 50%;
                max-width: none;
            }

            &_1 + &_2,
            &_3 {
                width: 66.666%;
            }
        }
    }
}

@media (--small) {
    .grid {
        &Column {
            width: 50%;

            &_2,
            &_3 {
                width: 100%;
                max-width: none;
            }
        }
    }
}
