@import 'mixins.pcss';

.widget {
    @mixin block-base;

    display: flex;
    width: 100%;
    min-height: 405px;
    padding: 16px 20px;
    background: var(--color-snow);

    &Inner {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        > .table {
            margin-top: 0;
        }

        > .graph {
            margin-top: 20px;
        }
    }

    &_small {
        min-height: 190px;
    }

    &_large {
        min-height: 550px;
    }

    &_table {
        padding-bottom: 0;
    }

    &_total {
        width: auto;
        min-height: 100px;
        padding-right: 60px;
    }

    [class~='SBA-segmented-item-label'] {
        padding: 0 !important;
    }
}

.tabs {
    &Item {
        flex: 1 auto;

        &Wrapper {
            width: 100%;
        }

        &Text {
            @mixin truncateWithEllipses;

            display: block;
            width: 100%;
            padding: 6px 12px;
        }
    }
}

.error {
    position: absolute;
    top: 15px;
    right: 20px;
    bottom: 15px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-carbon);

    &Text + &Link {
        margin-left: 3px;
    }

    &Link {
        @mixin primaryLink;
    }
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &Title {
        @mixin h2;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &Clickable {
            @mixin base-link var(--font-color);
        }
    }

    &Buttons {
        display: flex;
        gap: 0 var(--indent-md);
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
    }

    &SettingsIcon {
        width: 20px !important;
        height: 20px !important;
    }
}

.widget_total .error {
    position: static;
}

.widget_total .header {
    margin-bottom: 20px;
}

.table {
    margin: -10px -20px 0;
}

.count {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    text-align: center;

    &Number {
        @mixin h1-text;
    }

    &Label {
        display: block;
        color: var(--color-middle-gray-carbon);
        font-weight: 600;
    }
}

.total {
    &Graph {
        display: flex;
        align-items: center;
        height: 40px;
    }

    &Content {
        &Columns {
            display: inline-flex;
        }

        &Item {
            flex: 1 1 auto !important;
            padding-left: 25px;

            &:first-child {
                padding-left: 0;
            }
        }

        &Title {
            margin-bottom: 10px !important;
        }

        &Value {
            margin-bottom: 0 !important;
        }

        &Graph {
            margin-bottom: 10px;
        }
    }
}

.topRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
        display: flex;
        align-items: center;
    }

    &Item {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    &Graph {
        position: relative;
    }
}

.topGraph {
    width: 60px;
    height: 60px;
    margin-left: 20px;
}

.difference {
    margin-top: 12px;

    &.up {
        color: var(--color-arctic-ads);
    }

    &.down {
        color: var(--color-lichi);
    }
}

.reportMessage {
    display: flex;
    width: 100%;
    margin: auto;
}

@media (--small) {
    .total {
        &Content {
            &Item {
                padding-left: 20px;
            }

            &Graph {
                margin-top: 20px;
                margin-bottom: 5px;
            }
        }

        &Graph {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
        }
    }

    .topRow {
        flex-wrap: wrap;

        & > div {
            flex-wrap: wrap;
        }

        &Value {
            width: 100%;
        }

        &Graph {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    .difference {
        display: flex;
        align-items: center;
        margin-top: 0;
    }
}

@media (--big) {
    .total {
        &Content {
            &Item {
                padding-left: 40px;

                & > div {
                    display: flex;
                }
            }

            &Group {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                &:first-child {
                    margin-right: 20px;
                }
            }

            &Value,
            &Graph {
                margin: 0 !important;
            }
        }

        &Graph {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
        }
    }

    .topRow {
        &Graph {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .difference {
        margin-top: 0;
    }
}
