@import 'mixins.pcss';

.filterRules {
    @mixin customScrollbar;

    overflow-y: auto;
    width: 50%;
    height: 100%;
    padding-bottom: 5px;
    padding-left: 16px;

    &Zero {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &ZeroDesc {
        display: block;
        max-width: 260px;
        margin: 0 auto;
    }

    &Title {
        margin-right: 4px;
        font-weight: 600;
        white-space: nowrap;
    }

    &Divider {
        display: flex;
        align-items: center;
        margin: 16px 0;

        &Line {
            width: 100%;
            height: 1px;
            background-color: var(--color-gray-carbon);
        }
    }

    &AnyRule {
        display: flex;
        gap: 8px;
        align-items: center;

        &Small {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &BtnsWrapper {
        display: flex;
        gap: 8px;
        width: 100%;
    }

    &AddRuleBtn {
        margin-top: 16px;
    }

    & [class~='rc-virtual-list-scrollbar-thumb'] {
        background-color: var(--color-gray-carbon-rgb-50-opacity) !important;
    }
}
