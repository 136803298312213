.inputWrapper {
    margin-bottom: 20px !important;
}

.inlineGroup {
    display: flex;
}

.input {
    min-width: 300px;
    margin-right: 10px !important;
}

.hint {
    margin-left: 5px;
    fill: var(--color-blue-ads) !important;
}

.label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.selected {
    display: flex;
    flex-wrap: wrap;
    gap: var(--indent-sm);
    margin-top: 10px !important;
}

.chipButton {
    margin: var(--indent-sm) var(--indent-xs) 0 0 !important;
}

.closeIcon {
    padding: 2px;
}

.button {
    margin-left: 8px;
}
