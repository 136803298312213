.root {
    display: flex;
    align-items: center;
}

.switch {
    display: flex;
    align-items: center;

    &Label {
        margin-left: var(--indent-sm);
    }

    [class~='SBA-switch'] {
        transition: background-color .2s .4s;
    }

    [class='SBA-switch-handle'] {
        transition-delay: .4s;
    }
}

.progress {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 0;
    margin-left: 0;
    transition: all .2s;

    &Active {
        margin-left: 12px;
        transition-delay: .2s;
    }

    &Active,
    &Active &Bar {
        width: 104px;
    }
}

.disabled .progress {
    display: none;
}
