@import 'variables.pcss';
@import 'mixins.pcss';
@import 'reset.css';

body {
    width: 100%;
    min-width: var(--minBodyWidth);
    height: 100%;
    min-height: 100vh;
    background-color: var(--color-bg);
    color: var(--font-color);
    font: 400 16px/24px var(--font-family);
    letter-spacing: -.3px;
}

[class~='recharts-wrapper'] text {
    font-size: 12px;
    line-height: 16px;
}
