.periodFilter {
    display: flex;
    gap: 24px;
    align-items: flex-end;

    &Col {
        width: 50%;

        &:first-child {
            width: 225px;
            min-width: 225px;
        }
    }

    &SelectIcon {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
