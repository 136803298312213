.deleteSavedFilterModal {
    &Inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        p {
            margin-bottom: var(--indent-sm);
        }
    }
}
