@import 'mixins.pcss';

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}

.card {
    position: relative;
    overflow: visible;
    width: 25%;
    min-width: 305px;
    margin: 8px 0;
    padding: 0 8px;

    &Inner {
        @mixin block-base;
        @mixin animation-base box-shadow;

        display: flex;
        flex-direction: column;
        gap: var(--indent-sm);
        width: 100%;
        min-height: 100%;
        padding: var(--padding-md) var(--padding-lg) var(--padding-lg);
        border: 1px solid var(--simple-border-color);
        box-shadow: 0 0 0 rgba(0 0 0 / 0);
    }

    &:hover {
        z-index: 1;
    }

    &Report:hover {
        .image {
            height: 0;
        }

        .nav {
            display: block;
        }

        .cardInner {
            position: absolute;
            width: calc(100% - 16px);
            background: var(--color-hover-and-current);
            box-shadow: var(--sparse-box-shadow);
        }
    }

    &Create {
        color: var(--color-font);
        text-decoration: none;

        .image {
            background-color: var(--color-snow);
        }

        &:hover {
            .createIcon {
                fill: var(--color-blue-ads);
                transform: scale(1.3);
            }
        }
    }
}

.title {
    @mixin h3-text;

    font-size: 20px;
}

.description {
    @mixin default-text;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 140px;

    > svg {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.button {
    min-width: 150px;
    margin: var(--indent-md) auto 0 auto;
    cursor: pointer;
}

.nav {
    display: none;

    &Item {
        @mixin base-link;

        display: flex;
        align-items: center;
        padding: var(--indent-sm) 0;
        border-radius: var(--radius-md);
        color: var(--color-font);
        text-decoration: none;

        &Icon {
            width: 20px;
            height: 20px;
            margin-right: var(--indent-sm);
            fill: var(--color-middle-gray-carbon);
        }

        &:hover &Icon {
            fill: var(--color-cobalt);
        }
    }
}

.createIcon {
    width: 45px;
    height: 45px;
    fill: var(--color-gray-carbon);
    transition: transform .2s ease-in-out;
    transform: scale(1);
}

@media (max-width: 1367px) {
    .card {
        width: 50%;
    }

    .listNotVisebleAll .card:nth-child(3n) {
        display: none;
    }
}

@media (min-width: 1367px) {
    .card {
        width: 33.333%;
    }
}

@media (--medium) {
    .listNotVisebleAll .card:nth-child(4n) {
        display: none;
    }
}

@media (--big) {
    .card {
        width: 25%;
    }
}
