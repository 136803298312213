@import 'mixins.pcss';

.root {
    --gap-width: var(--indent-lg);

    display: flex;
    flex-direction: row;
    gap: var(--gap-width);
}

.sidebar {
    max-width: 25%;

    /* имитируем 3 колонки из 12 */
    max-width: calc((100% - 11 * var(--gap-width)) / 4 + 2 * var(--gap-width));
    transition: width .2s ease-out, flex .2s ease-out;
}

.content {
    flex: auto;
    transition: width .2s ease-out, flex .2s ease-out;
}

/* TODO: временный фикс, как будет понятно что делать с навигацией, пофиксить */
[class*='-isLoading'] .sidebar {
    opacity: 0;
}
