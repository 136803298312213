@import 'mixins.pcss';

.root {
    display: flex;
    flex-direction: column;
}

.table {
    @mixin block-base;

    width: 100%;

    &NoResults {
        @mixin block-base;

        min-height: 200px;
    }
}

.loader:after {
    border-radius: var(--radius-md);
}
