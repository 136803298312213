.logo {
    display: flex;
    gap: 0 8px;

    &Link {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
    }
}
