@import 'mixins.pcss';

.wrapper {
    position: fixed;
    z-index: 1090 !important;
    padding-top: 72px;

    [class~='SBA-modal'] {
        z-index: 1080;
        width: 100% !important;
        max-width: calc(100vw - 48px) !important;
    }

    [class~='SBA-modal-close'] {
        display: none;
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 0;

        .icon {
            position: relative;
            width: 60px;
            height: 60px;
            border: 2px solid;
            border-color: var(--color-blue-carbon);
            border-radius: 50%;
        }

        .icon:before,
        .icon:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 32px;
            border-radius: 1px;
            background-color: var(--color-lichi);
        }

        .icon:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        .icon:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        .title {
            margin-top: 12px;
            color: var(--color-lichi);
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
        }

        .content {
            margin-top: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -.3px;
            text-align: center;
        }

        .button {
            margin-top: 24px;
        }
    }
}
