body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
button,
select,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

ol,
ul,
menu {
    list-style: none;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-weight: normal;
    font-style: normal;
}

input,
textarea,
button,
select {
    border: 0 none;
    background-color: transparent;
    color: inherit;
    outline: 0;
    font-size: inherit;
    font-family: inherit;
}

button {
    font-weight: inherit;
}

a {
    text-decoration: none;
}

a,
button {
    cursor: revert;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
