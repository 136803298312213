@import 'mixins.pcss';

.root {
    position: relative;
}

.search {
    width: 100%;
    padding: var(--padding-md) var(--padding-sm);
}

.list {
    &Scroller {
        @mixin customScrollbar;

        position: relative;
        overflow-y: auto;
        height: 100%;
    }

    &Item {
        @mixin default-text;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        cursor: pointer;

        &Title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: var(--indent-sm) var(--padding-sm);
            border-radius: var(--radius-sm);
            background: var(--color-snow);
            color: var(--font-color);
            cursor: pointer;

            &:hover {
                background-color: var(--color-selected);
                color: inherit;
            }
        }

        &Main {
            margin-bottom: var(--padding-md);
            padding-bottom: var(--padding-md);
            border-bottom: 1px solid var(--simple-border-color);

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            > .listItem .listItem {
                padding-left: 30px;
            }

            > .listItemTitle {
                font-weight: 600;
            }
        }

        &ArrowIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            color: var(--color-middle-gray-carbon);
            transition: var(--base-animation);
            transform: rotate(0);

            &.expanded {
                color: var(--color-blue-carbon);
                transform: rotate(-180deg);
            }
        }
    }

    &BottomBound {
        width: 100%;
        height: 2px;
    }
}
