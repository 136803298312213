.filtersContent {
    &Search {
        margin-bottom: var(--indent-lg);
    }

    &Inner {
        display: flex;
        height: calc(100vh - 355px);
        min-height: 250px;
        margin-bottom: 8px;
    }
}
