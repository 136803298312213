@import 'mixins.pcss';

.icon {
    display: flex;
    cursor: pointer;

    &Up,
    &Down {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        color: var(--color-middle-gray-carbon);

        &:hover {
            color: var(--color-blue-carbon);
        }

        &.disabled {
            color: var(--color-gray-carbon);
            cursor: default;
        }
    }

    &Up {
        margin-right: var(--indent-xs);
        transform: rotate(180deg);
    }

    &Active {
        color: var(--color-blue-carbon);
        cursor: default;
    }
}
