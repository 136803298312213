@import 'mixins.pcss';

.root {
    flex-shrink: 0;
    width: 15px;
    margin-right: 20px;

    > span {
        overflow: hidden;
    }
}

.arrow {
    @mixin iconDefaultSize;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &Up {
        color: var(--color-arctic-ads);
        transform: rotate(180deg);
    }

    &Down {
        color: var(--color-lichi);
    }
}
