.filter {
    &Content {
        overflow: visible;
        width: 380px;
        padding: var(--padding-lg) !important;
    }

    &Fields {
        display: flex;
        gap: 0 var(--indent-md);

        &Item {
            flex-basis: 50%;
        }
    }

    &Actions {
        display: flex;
        gap: 0 var(--padding-lg);
        justify-content: center;
        margin-top: var(--indent-md);

        &Item {
            min-width: 110px;
        }
    }

    &Anchor {
        width: 16px !important;
        height: 20px !important;
        min-height: 20px !important;
    }
}
